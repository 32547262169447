const state = {
  yyyy: new Date(2021, 11, 31).toISOString().substr(0, 4),
};

// getters
const getters = {
  yyyy: (state) => state.yyyy,
};

// actions
const actions = {};

// mutations
const mutations = {
  change(state, date) {
    state.yyyy = date;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
