import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        purple: "#6d1bff",
        orange: "#FFA62E",
        pink: "#FD4D5C",
        grey: "#f9f9f9",
        green: "#173e43",
      },
    },
  },
});
export default vuetify;
