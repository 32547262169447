import Vue from "vue";
import VueRouter from "vue-router";
import { fb } from "@/firebase";
import { default as i18n } from "@/i18n";
import store from "@/store";
import AffiliatedTeachersView from "../views/schools/teachers/affiliatedTeachers/AffiliatedTeachersView";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`,
    name: "default",
  },

  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "test",
        name: "test",
        component: () => import("../views/_testing/_TestingView.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "admin/settings",
        name: "admin-settings",

        component: () =>
          import("../views/settings/adminSettings/_AdminSettingsView.vue"),
        meta: { requiresAdmin: true, requiresAuth: true },
      },
      {
        path: "studio",
        name: "studio",
        component: () =>
          import("../views/admin/adminStudio/_AdminStudioView.vue"),
        meta: { requiresAdmin: true, requiresAuth: true },
      },
      {
        path: "settings",
        name: "settings",
        component: () =>
          import("../views/settings/schoolSettings/_SchoolSettingsView.vue"),
        meta: { requiresAdmin: false, requiresAuth: true },
      },

      {
        path: "analytics",
        name: "analytics",
        component: () => import("../views/schools/Analytics.vue"),
        meta: { requiresAdmin: false, requiresAuth: true },
      },
      {
        path: "article-preview/:id",
        name: "articlePreview",
        component: () =>
          import("../views/articles/articlesPreview/_ArticlePreviewView.vue"),
        meta: { requiresAdmin: true, requiresAuth: true },
      },

      {
        path: "/",
        name: "homeView",
        component: () => import("../views/home/_HomeView.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "landing",
        name: "landing",
        component: () => import("../views/landing/_LandingView.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "portfolio",
        name: "Portfolio",
        component: () => import("../views/portfolio/_PortfolioView.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "blog/news",
        name: "blogView",
        component: () => import("../views/blog/_BlogView.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "blog/educatie",
        name: "blogEducation",
        component: () =>
          import("../views/blog/educationBlog/_EducationBlogView.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "blog/jocuri-video",
        name: "blogGaming",
        component: () => import("../views/blog/gamingBlog/_GamingBlogView.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "blog/:id",
        name: "articleView",
        component: () =>
          import("../views/blog/articleBlog/_ArticleBlogView.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "school/parents",
        name: "AffiliatedParents",
        component: () => import("../views/schools/AffiliatedParents.vue"),
        meta: { requiresAdmin: false, requiresAuth: true },
      },
      {
        path: "gradinite-scoli/:id1/:id2",
        name: "schools",
        component: () =>
          import("../views/search/schools/_SearchSchoolsView.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },

      {
        path: "cluburi/:id1/:id2",
        name: "clubs",
        component: () => import("../views/SearchClubs.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "cursuri/:id1/:id2/:id3/:id4",
        name: "filter",
        component: () => import("../views/FilterCourses.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },

      {
        path: "curs/:id1/:id2/:id3",
        name: "clubs course",
        component: () => import("../views/Course.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "contact",
        name: "contact",
        component: () => import("../views/Contact.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "legal/terms",
        name: "terms",
        component: () => import("../views/services/Terms&Conditions.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "legal/privacy",
        name: "privacy",
        component: () => import("../views/services/PrivacyPolicy.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "abonamente",
        name: "subscriptions",
        component: () => import("../components/landing/Subscriptions.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },

      {
        path: ":id",
        name: "profiles",
        component: () => import("../views/Profiles.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },

      {
        path: "hub/:id",
        name: "hub",
        component: () => import("../views/Hub.vue"),
        meta: { requiresAdmin: false, requiresAuth: true },
      },

      {
        path: "store/:id",
        name: "store id",
        component: () => import("../views/shop/_ShopView.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "store",
        name: "store",
        component: () => import("../views/shop/_ShopView.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "school/teachers",
        name: "AffiliatedTeachers",
        component: AffiliatedTeachersView,
        meta: { requiresAdmin: false, requiresAuth: true },
      },
      {
        path: "messages/:id",
        name: "messagesHub",
        component: () => import("../views/Messages.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
      {
        path: "pages/:id",
        name: "pageId",
        component: () => import("../views/Official.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },

      {
        path: "admin/articles",
        name: "adminArticles",
        component: () => import("../views/articles/_ArticlesSettingsView.vue"),
        meta: { requiresAdmin: true, requiresAuth: true },
      },
      // {
      //   path: "admin/articles-modules",
      //   name: "adminArticlesModules",
      //   meta: { requiresAuth: true },
      //   component: () =>
      //     import("../views/articles/articlesModules/_ArticlesModulesView.vue"),
      // },
      {
        path: "admin/:id",
        name: "admin/",
        component: () => import("../views/admin/_AdminView.vue"),
        meta: { requiresAdmin: true, requiresAuth: true },
      },
      {
        path: "*",
        component: import("../components/PageNotFound.vue"),
        meta: { requiresAdmin: false, requiresAuth: false },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
router.beforeEach(async (to, from, next) => {
  fb.auth().onAuthStateChanged(async (user) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresAdmin = to.matched.some(
      (record) => record.meta.requiresAdmin
    );
    let admin = null;
    if (user) {
      const token = await user.getIdTokenResult();
      admin = await token.claims.admin;
    }

    if (requiresAuth) {
      if (user) {
        if (requiresAdmin) {
          if (admin) {
            return next();
          }
          return next({ name: "default" });
        }
        return next();
      }
      return next({ name: "default" });
    }
  });
  store.commit("SET_ROUTE", to);
  store.commit("SET_OVERLAY");
  return next();
});

export default router;
