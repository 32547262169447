import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBE364tAUNjS5a85O-90Op0CwWamDE0Pf8",
  authDomain: "eduprivate2020.firebaseapp.com",
  databaseURL: "https://eduprivate2020.firebaseio.com",
  projectId: "eduprivate2020",
  storageBucket: "eduprivate2020.appspot.com",
  messagingSenderId: "18880745761",
  appId: "1:18880745761:web:1967de915b4c979daf6e60",
  measurementId: "G-79GXBVR3P7",
};
const firebaseConfigVforce = {
  apiKey: "AIzaSyD9k_c-WfWXkYXJ0YHUSEkBcrwUKH6bo5k",
  authDomain: "vimnite2021.firebaseapp.com",
  databaseURL:
    "https://vimnite2021-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "vimnite2021",
  storageBucket: "vimnite2021.appspot.com",
  messagingSenderId: "824711532578",
  appId: "1:824711532578:web:149751a9f9d880acdafa7b",
  measurementId: "G-VHXD84N9DB",
};

const fb = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const rtdb = firebase.database();
const fn = firebase.functions();
const app = firebase.initializeApp(firebaseConfig, "Secondary");

const timeStamp = firebase.firestore.FieldValue.serverTimestamp;
const fieldValue = firebase.firestore.FieldValue;

const vforce = firebase.initializeApp(firebaseConfigVforce, "vforce");
const dbVforce = vforce.firestore();

export { db, fb, timeStamp, fieldValue, rtdb, fn, app, dbVforce };
