<template>
  <div>
    <h5 class="ml-6 mt-3" v-if="profileType === 'User'">Pagina oficiala</h5>
    <v-list-item v-if="!pageType && profileType === 'User'">
      <v-list-item-title class="router-link-menu">
        <v-btn color="purple" @click.stop="dialogConfirm = true">
          <v-icon left color="white">mdi-plus</v-icon>
          <span class="white--text custom-transform-class text-none"
            >Creaza pagina</span
          >
        </v-btn>

        <v-dialog v-model="dialogConfirm" max-width="450">
          <v-card class="pa-5">
            <v-row justify="center" class="pa-0 ma-0">
              <v-col cols="10">
                <v-card-title class="headline"
                  >Creare pagina oficiala</v-card-title
                >

                <v-card-text
                  >Selecteaza tipul de slujba pentru care doresti sa aplici din
                  lista de mai jos:</v-card-text
                >
              </v-col>
              <v-col cols="9">
                <v-overflow-btn
                  class="my-2 pointer"
                  :items="pageTypes"
                  v-model="type"
                  item-color="purple"
                  color="purple"
                  label="Tip pagina"
                ></v-overflow-btn>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="black"
                class="custom-transform-class text-none"
                text
                @click="dialogConfirm = false"
                >Inchide</v-btn
              >

              <router-link
                v-if="profileType === 'User'"
                class="router-link"
                :to="`/pages/${profileUserId}`"
                link
              >
                <v-btn
                  color="purple"
                  class="custom-transform-class text-none"
                  @click="(dialogConfirm = false), addOfficialPage(type)"
                  >Continua</v-btn
                >
              </router-link>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-list-item-title>
      <v-list-item-title v-if="pageType">
        <router-link
          v-if="profileType === 'User'"
          class="router-link"
          :to="`/pages/${profileUserId}`"
          link
        ></router-link>
      </v-list-item-title>
    </v-list-item>

    <router-link
      v-if="profileType === 'User'"
      class="router-link"
      :to="`/pages/${profileUserId}`"
      link
    >
      <v-list-item v-if="pageType && profileType === 'User'">
        <v-list-item-title class="router-link-menu">
          <v-icon left>mdi-page-next</v-icon>
          <span class="black--text">{{ teacher.name }}</span>
        </v-list-item-title>
      </v-list-item>
    </router-link>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "UserDropdown",
  data() {
    return {
      dialogConfirm: false,
    };
  },
  computed: {
    ...mapState(["pageType", "profileType", "profileUserId", " "]),
  },
};
</script>
