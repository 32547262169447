<template>
  <div>
    <h5 class="ml-6 mt-3">Ajutor si legal</h5>
    <router-link class="router-link" :to="`/${$i18n.locale}/landing`" link>
      <v-list-item>
        <v-list-item-title class="router-link-menu">
          <v-icon left color="purple">mdi-account</v-icon>
          <span class="black--text">{{ $t("dropdown.about") }}</span>
        </v-list-item-title>
      </v-list-item>
    </router-link>
    <router-link class="router-link" :to="`/${$i18n.locale}/contact`" link>
      <v-list-item>
        <v-list-item-title class="router-link-menu">
          <v-icon color="purple" left>mdi-message</v-icon>
          <span class="black--text">{{ $t("dropdown.contact") }}</span>
        </v-list-item-title>
      </v-list-item>
    </router-link>
    <router-link class="router-link" :to="`/${$i18n.locale}/blog/news`" link>
      <v-list-item>
        <v-list-item-title class="router-link-menu">
          <v-icon color="purple" left>mdi-post</v-icon>
          <span class="black--text">Blog</span>
        </v-list-item-title>
      </v-list-item>
    </router-link>
    <router-link class="router-link" :to="`/${$i18n.locale}/abonamente`" link>
      <v-list-item>
        <v-list-item-title class="router-link-menu">
          <v-icon left color="purple">mdi-credit-card-outline</v-icon>
          <span class="black--text">{{ $t("dropdown.subscriptions") }}</span>
        </v-list-item-title>
      </v-list-item>
    </router-link>
    <!-- <router-link class="router-link" to="/jobs/" link>
              <v-list-item>
                <v-list-item-title class="router-link-menu">
                  <v-icon left></v-icon>
                  <span class="black--text">Locuri de munca</span>
                </v-list-item-title>
              </v-list-item>
            </router-link>-->
    <!-- <router-link class="router-link" to="/services/pricing/" link>
              <v-list-item>
                <v-list-item-title class="router-link-menu">
                  <v-icon left></v-icon>
                  <span class="black--text">Intrebari frecvente</span>
                </v-list-item-title>
              </v-list-item>
            </router-link>-->
    <router-link
      class="router-link"
      :to="`/${$i18n.locale}/legal/privacy/`"
      link
    >
      <v-list-item>
        <v-list-item-title class="router-link-menu">
          <v-icon left color="purple">mdi-text-box-search-outline</v-icon>
          <span class="black--text">{{ $t("dropdown.privacy") }}</span>
        </v-list-item-title>
      </v-list-item>
    </router-link>
    <router-link class="router-link" :to="`/${$i18n.locale}/legal/terms/`" link>
      <v-list-item>
        <v-list-item-title class="router-link-menu">
          <v-icon left color="purple">mdi-file-sign</v-icon>
          <span class="black--text">{{ $t("dropdown.terms") }}</span>
        </v-list-item-title>
      </v-list-item>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "HelpDropdown",
};
</script>
<style scoped></style>
