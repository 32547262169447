import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import { fb } from "@/firebase";
import Vuetify from "vuetify";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueMeta from "vue-meta";
import VueSocialSharing from "vue-social-sharing";
import * as VueGoogleMaps from "vue2-google-maps";
import i18n from "./i18n";

Vue.config.productionTip = false;

const ignoreWarnMessage =
  "The .native modifier for v-on is only valid on components but it was used on <div>.";
Vue.config.warnHandler = function (msg) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    return (msg = null);
  }
};

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBHUvQaK5zUoIbfqMGmXbP_fbA9RvuaX4E",
    libraries: "places",
    installComponents: true,
  },
});
Vue.use(VueMeta);
Vue.use(Vuetify);
Vue.use(VueSocialSharing);
Vue.config.productionTip = false;

let app = "";

fb.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      vuetify,
      VueSocialSharing,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
