<template>
  <v-container fluid grid-list-xs>
    <v-row justify="center" class="mt-10">
      <v-col cols="12" sm="9">
        <v-container grid-list-xs>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        color="purple"
                        v-model="editedItem.position"
                        label="Position"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        color="purple"
                        v-model="editedItem.years"
                        label="Years"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        color="purple"
                        v-model="editedItem.description"
                        label="Descriere"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple" text @click="close">Inchide</v-btn>
                <v-btn color="purple" text @click="saveItem(editedItem)"
                  >Salveaza</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-row>
            <v-col cols="12" sm="5">
              <v-data-table
                v-model="selected"
                :headers="computedHeaders"
                :items="affiliated"
                orderby
                disable-sort
                hide-default-footer
                class="elevation-1"
              ></v-data-table>
            </v-col>
            <v-col cols="12" sm="7">
              <v-data-table
                v-model="selected"
                :headers="displayHeaders"
                :items="approvedRequests"
                orderby
                disable-sort
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <div class="d-inline-flex">
                    <v-icon small @click="editItem(item)" class="mr-2"
                      >edit</v-icon
                    >
                    <v-icon
                      small
                      class="mr-2"
                      @click="deleteAffiliated(item.docId)"
                      >delete</v-icon
                    >
                  </div>
                </template>
                <template v-slot:[`item.time`]="{ item }">{{
                  item.time + " - " + item.endTime
                }}</template>
                <template v-slot:[`item.description`]="{ item }">{{
                  item.description.substring(0, 75) + "..."
                }}</template>

                <template v-slot:[`item.userId`]="{ item }">
                  <router-link
                    class="router-link"
                    :to="`/pages/${item.teacherId}`"
                    link
                  >
                    <v-btn small elevation="0" class="my-2">
                      <span class="text-capitalize">Visit</span>
                    </v-btn>
                  </router-link>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" sm="2" class="mt-2">
        <v-card>
          <h3 class="px-4 ml-1 pt-4">Cereri</h3>
          <v-subheader class="ml-1 mb-1"
            >Trimite cerere de afiliere</v-subheader
          >

          <v-row justify="center">
            <v-col cols="8">
              <v-text-field
                type="email"
                class="my-0 pa-0 pb-2"
                :error-messages="
                  alreadySent ? 'Acest email a fost deja adaugat' : []
                "
                :placeholder="alreadySent ? 'Incearca alt email' : 'Email'"
                color="purple"
                v-model="request"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                x-small
                dark
                color="purple"
                class="mt-3 ml-2"
                @click="sendAffiliatedReq()"
              >
                <v-icon small>mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-for="(req, i) in requests" :key="i" class="mt-4 pointer">
          <router-link class="router-link" :to="`/pages/${req.teacherId}`" link>
            <v-row justify="center" class="py-2">
              <v-avatar size="60">
                <v-img :src="req.teacherPhoto" width="30px" alt="John" />
              </v-avatar>
            </v-row>
            <v-row justify="center">
              <v-chip
                small
                v-if="req.status === 'pending'"
                class="white--text"
                label
                color="orange"
                >Request {{ req.status }}</v-chip
              >
              <v-chip
                v-if="req.status === 'Accepted'"
                small
                class="white--text"
                label
                color="purple"
                >{{ req.status }}</v-chip
              >
            </v-row>
            <v-row justify="center" class="px-4 pt-2">
              <h3 class="black--text">{{ req.to }}</h3>
            </v-row>
            <v-row justify="center">
              <span class="smaller">{{ req.date }}</span>
            </v-row>
          </router-link>
          <v-row justify="center" class="py-2">
            <v-chip small label color="purple" outlined class="pointer">
              <span class="black--text pointer">{{ req.email }}</span>
            </v-chip>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { db } from "@/firebase";
import { mapState } from "vuex";

export default {
  name: "AffiliatedTeachersView",
  data() {
    return {
      tab: 0,
      request: null,
      dialog: false,
      affiliated: [],
      editedIndex: -1,
      alreadySent: false,
      account: {},
      editedItem: {
        position: "",
        years: "",
        description: "",
      },
      selected: [],
      requests: [],
      approvedRequests: [],
      photoList: [],
      displayHeaders: [
        { text: "Pozitie", value: "position" },
        { text: "Years", value: "years" },
        { text: "Descriere", value: "description" },
        { text: "Profile", value: "userId" },
        { text: "Actions", value: "action", sortable: false },
      ],
      headers: [
        {
          text: "Nume",
          align: "left",
          value: "name",
        },
        { text: "Mobil", value: "mobilePhone" },

        { text: "Email", value: "email" },
      ],
    };
  },
  computed: {
    ...mapState(["user", "account", "profileUserId"]),
    computedHeaders() {
      return this.headers.filter((item) => {
        if (this.profileUserId === this.account.userId) {
          return item;
        } else if (item.text !== "Actions") {
          return item;
        }
      });
    },
    computedHeadersInfo() {
      return this.displayHeaders.filter((res) => {
        if (!this.user && res.text !== "Actions") {
          return res;
        } else if (this.user) {
          return res;
        }
      });
    },
  },
  created() {
    // this.getRequestedTeachers();
    this.getTeachers();
  },
  methods: {
    getRequestedTeachers() {
      const profileId = this.profileUserId;
      db.collection("requests")
        .where("requestType", "==", "Teacher")
        .where("userId", "==", profileId)
        .orderBy("email", "desc")
        .onSnapshot((docSnapshot) => {
          let events = [];
          let approvedEvents = [];
          docSnapshot.forEach((doc) => {
            let appData = doc.data();
            events.push(appData);
            if (appData.status !== "pending") {
              approvedEvents.push(appData);
            }
          });
          events.filter((doc, index) => {
            db.collection("teachers")
              .doc(doc.teacherId)
              .get()
              .then((docSnapshot) => {
                if (!docSnapshot.exists) return;
                let photoUrl = docSnapshot.data().profilePhoto;
                this.$set(this.requests[index], "teacherPhoto", photoUrl);
              });
          });
          this.approvedRequests = approvedEvents;
          this.requests = events;
        });
    },
    getTeachers() {
      const profileId = this.profileUserId;
      db.collection("teachers")
        .where("affiliated", "==", profileId)
        .orderBy("email", "desc")
        .onSnapshot((docSnapshot) => {
          let events = [];
          docSnapshot.forEach((doc) => {
            let appData = doc.data();
            if (appData.profilePhoto) {
              events.push(appData);
            }
          });
          this.affiliated = events;
        });
    },

    sendAffiliatedReq() {
      const profileId = this.profileUserId;
      let vm = this;
      db.collection("requests")
        .where("email", "==", this.request)
        .where("requestType", "==", "Teacher")
        .get()
        .then((docSnapshot) => {
          let exists = docSnapshot.docs.length;
          if (exists === 0) {
            db.collection("teachers")
              .where("email", "==", this.request)
              .where("affiliated", "==", "")
              .get()
              .then((docSnapshot) => {
                let teacher = docSnapshot.docs[0].data();
                let newRequest = {
                  userId: profileId,
                  from: vm.account.name,
                  years: "",
                  description: "",
                  position: "",
                  to: teacher.name,
                  email: teacher.email,
                  requestType: "Teacher",
                  date: new Date(),
                  teacherId: teacher.userId,
                  status: "pending",
                };
                db.collection("requests")
                  .add(newRequest)
                  .then((docSnapshot) => {
                    let updateRequest = {
                      docId: docSnapshot.id,
                      userId: profileId,
                    };
                    db.collection("requests")
                      .doc(updateRequest.docId)
                      .update(updateRequest)
                      .then(() => {
                        this.request = "";
                      });
                  });
              });
          } else if (exists === 1) {
            this.request = "";
            this.alreadySent = true;
          }
        });
    },
    updateRequest(item) {
      let newReq = {
        position: item.position,
        years: item.years,
        description: item.description,
      };
      db.collection("requests").doc(item.docId).update(newReq);
    },
    editItem(item) {
      this.editedIndex = this.affiliated.indexOf(item);
      this.editedItem = item;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    saveItem(item) {
      this.updateRequest(item);
      this.close();
    },
  },
};
</script>
<style scoped>
.smaller {
  font-size: 14px;
}
.v-input input {
  padding: 0px;
}
.v-input input#input64 {
  padding: 0px;
}
.router-link {
  text-decoration: none;
}
.router-link span {
  color: black;
}
.router-link-menu {
  text-decoration: none;
}
.router-link-menu span {
  color: black;
}
</style>
