<template>
  <v-toolbar-items>
    <v-menu offset-y class="mr-2">
      <template v-slot:activator="{ on }">
        <v-btn text dark v-on="on" @click="show = !show">
          <v-chip color="white" class="py-5 px-2 mx-0 pointer">
            <v-avatar
              size="50"
              class="mr-2"
              v-show="user && adminPhoto"
              v-if="user"
            >
              <v-img height="150px" :src="adminPhoto" alt="alt"></v-img>
            </v-avatar>
            <v-avatar
              size="50"
              class="mr-2"
              v-show="user && profilePhoto"
              v-if="user"
            >
              <v-img height="150px" :src="profilePhoto" alt="alt"></v-img>
            </v-avatar>
            <v-icon v-if="!user" size="30" color="purple"
              >mdi-account-circle
            </v-icon>
            <v-icon color="black">{{
              show ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-chip>
        </v-btn>
      </template>

      <v-list style="padding: 0px">
        <v-list-item-group>
          <v-list-item
            :to="`/${$i18n.locale}/${profileUsername}/`"
            v-if="user && profileType === 'School'"
          >
            <v-container fluid grid-list-xs>
              <v-row>
                <v-col cols="2" sm="1" v-if="profilePhoto">
                  <v-avatar
                    size="50"
                    class="mr-2"
                    v-show="user && profilePhoto"
                  >
                    <v-img
                      height="150px"
                      contain
                      :src="profilePhoto"
                      alt="alt"
                    ></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="10" offset-sm="1" sm="9" class="mt-3">
                  <div class="pl-6 black--text">
                    {{ profileUsername }}
                  </div>
                  <div class="hidden-xs-only pl-6 black--text">
                    {{ profileEmail }}
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item>
          <v-list-item
            :to="`/${$i18n.locale}/admin/${profileUsername}`"
            v-show="user && profileAdmin"
            class="pointer"
          >
            <v-list-item-icon class="mr-3 my-3">
              <v-icon color="purple">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="pt-4">
              <v-list-item-title class="black--text">{{
                $t("dropdown.adminProfile")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Different options area for multi purpose -->
          <div v-for="(option, i) in userSettings" :key="'B' + i" v-show="user">
            <v-list-item
              v-show="option.category === profileType"
              :to="`/${$i18n.locale}/${option.route}/`"
              class="pointer"
            >
              <v-list-item-icon class="mr-3 my-3">
                <v-icon color="purple">{{ option.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pt-4">
                <v-list-item-title
                  class="black--text"
                  v-if="option.type === 'settings'"
                  >{{ $t("dropdown.settings") }}</v-list-item-title
                >
                <v-list-item-title
                  class="black--text"
                  v-if="option.type === 'analytics'"
                  >{{ $t("dropdown.analytics") }}</v-list-item-title
                >
                <v-list-item-title
                  class="black--text"
                  v-if="option.type === 'teachers'"
                  >{{ $t("dropdown.teachers") }}</v-list-item-title
                >
                <v-list-item-title
                  class="black--text"
                  v-if="option.type === 'parents'"
                  >{{ $t("dropdown.parents") }}</v-list-item-title
                >
                <v-list-item-title
                  class="black--text"
                  v-if="option.type === 'articles'"
                  >{{ $t("dropdown.articles") }}</v-list-item-title
                >
                <v-list-item-title
                  class="black--text"
                  v-if="option.type === 'studio'"
                  >Studio</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="option.divider && option.category === profileType"
            ></v-divider>
          </div>
          <v-list-item style="cursor: pointer" class="py-1" v-if="!user">
            <Login />
          </v-list-item>
          <v-divider></v-divider>
          <UserDropdown />
          <HelpDropdown />
          <v-divider></v-divider>
          <!-- Logout -->
          <v-list-item
            style="cursor: pointer"
            class="py-1"
            @click="logout()"
            v-if="user"
          >
            <v-list-item-title>
              <v-icon left color="error">mdi-logout-variant</v-icon>
              <span>{{ $t("dropdown.logout") }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <!-- <v-btn
      :to="`/hub/${request.userId}`"
      text
      link
      v-if="
        (user && request && request.requestType === 'Parent') ||
        request.requestType === 'Teacher'
      "
    >
      <router-link class="router-link" :to="`/hub/${request.userId}`">
        <span class="white--text ml-2">
          <v-icon left class="white--text">mdi-view-dashboard</v-icon>
        </span>
      </router-link>
    </v-btn> -->
  </v-toolbar-items>
</template>
<script>
import { fb } from "@/firebase";
import { mapState } from "vuex";
import Login from "../../views/auth/Login";
import HelpDropdown from "../dropdown/HelpDropdown.vue";
import UserDropdown from "../dropdown/UserDropdown.vue";

export default {
  name: "DropdownNav",
  components: {
    HelpDropdown,
    UserDropdown,
    Login,
  },
  data() {
    return {
      show: false,
      adminProfiles: [
        {
          type: "admin",
          route: "admin/",
          icon: "mdi-account-arrow-right-outline",
          name: "Admin Profile",
        },
        {
          type: "school",
          route: "/",
          icon: "mdi-account-arrow-right-outline",
          name: "School Profile",
        },
      ],
      userSettings: [
        {
          type: "settings",
          category: "School",
          route: "settings",
          icon: "mdi-account-arrow-right-outline",
          description: "Settings",
        },
        {
          type: "settings",
          category: "Admin",
          route: "admin/settings",
          icon: "mdi-account-arrow-right-outline",
          description: "Admin settings",
          divider: true,
        },
        {
          type: "analytics",
          category: "School",
          route: "analytics",
          icon: "mdi-home-analytics",
          description: "Statistics school",
          divider: true,
        },
        {
          type: "teachers",
          category: "School",
          route: "school/teachers",
          icon: "mdi-human-male-board",
          description: "Teachers schools",
        },
        {
          type: "parents",
          category: "School",
          route: "school/parents",
          icon: "mdi-account-group",
          description: "Parents schools",
          divider: true,
        },
        {
          type: "articles",
          category: "Admin",
          route: "admin/articles",
          icon: "mdi-newspaper",
          description: "Articles in admin",
        },
        {
          type: "studio",
          category: "Admin",
          route: "studio",
          icon: "mdi-folder",
          description: "Studio in admin",
          divider: true,
        },
      ],
    };
  },
  computed: {
    ...mapState([
      "user",
      "profilePhoto",
      "profileEmail",
      "profileUsername",
      "profileType",
      "profilePhoto",
      "profileUserId",
      "profileUsername",
      "profileEmail",
      "profileType",
      "subscription",
      "profileAdmin",
    ]),
  },

  methods: {
    logout() {
      fb.auth()
        .signOut()
        .then(() => {
          this.$router.replace("/" + this.$i18n.locale);
        });
    },
  },
};
</script>
<style scoped></style>
