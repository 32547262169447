<template>
  <div>
    <v-toolbar-title
      class="mr-1"
      style="cursor: pointer; z-index: 30"
      @click.prevent="$vuetify.goTo('#overview', { offset: 0 })"
    >
      <v-avatar size="55">
        <router-link class="router-link" :to="`/${$i18n.locale}/`">
          <v-img
            :src="logoIcon"
            height="150"
            max-width="100"
            alt="Profile Photo"
          />
        </router-link>
      </v-avatar>
      <router-link
        class="router-link hidden-xs-only"
        :to="`/${$i18n.locale}/`"
        v-if="
          route === 'blogView' ||
          route === 'blogEducation' ||
          route === 'blogGaming' ||
          route === 'article'
        "
      >
        <span class="d-inline-flex">
          <p class="white--text mx-5 mt-4">BLOG</p>
        </span>
      </router-link>
      <router-link
        class="router-link hidden-xs-only"
        :to="`/${$i18n.locale}/`"
        v-if="route === 'homeView'"
      >
        <span class="d-inline-flex">
          <p class="white--text ml-1 mr-5 mt-4">EDUPRIVATE</p>
        </span>
      </router-link>
    </v-toolbar-title>
  </div>
</template>
<script>
import logo from "../../assets/owlearningWhite.png";
import { mapState } from "vuex";
export default {
  name: "LogoNav",
  data() {
    return {
      logoIcon: logo,
    };
  },
  computed: {
    ...mapState(["route"]),
  },
  created() {},
  methods: {},
};
</script>
