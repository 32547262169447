var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{class:_vm.isTransparent && _vm.route === 'homeView' ? 'transparent' : 'purple',attrs:{"app":"","elevation":"0"}},[_c('LogoNav'),(
      _vm.route !== 'homeView' &&
      _vm.route !== 'blogView' &&
      _vm.route !== 'blogEducation' &&
      _vm.route !== 'blogGaming' &&
      _vm.route !== 'articleView'
    )?_c('SearchBar'):_vm._e(),_c('NavLinks'),_c('v-spacer'),_c('ProfileNav')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }