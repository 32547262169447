<template>
  <v-toolbar-items>
    <LanguageSwitcher />
    <v-btn
      :to="`/${$i18n.locale}/hub/${profileUserId}`"
      class="hidden-xs-only"
      text
      link
      v-if="profileType === 'School' && user && subscription !== 'default'"
    >
      <span class="white--text ml-2">
        <v-icon left class="white--text">mdi-view-dashboard</v-icon>
      </span>
    </v-btn>
    <MessagesNav />
    <DropdownNav />
  </v-toolbar-items>
</template>
<script>
import { mapState } from "vuex";
import DropdownNav from "../../dropdown/_DropdownNav";
import LanguageSwitcher from "../../LanguageSwitcher";
import MessagesNav from "../../navigation/profileNavigation/MessagesNav";
export default {
  name: "UserNav",
  computed: {
    ...mapState(["profileType", "user", "subscription", "profileUserId"]),
  },
  components: { DropdownNav, LanguageSwitcher, MessagesNav },
  data() {
    return {};
  },
};
</script>
<style scoped></style>
