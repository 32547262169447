<template>
  <v-toolbar-items>
    <v-toolbar-items
      v-if="
        route === 'blogView' ||
        route === 'blogEducation' ||
        route === 'blogGaming' ||
        route === 'articleView'
      "
      class="hidden-xs-only"
    >
      <v-btn :to="`/${$i18n.locale}/blog/news`" text>
        <span class="white--text">
          <v-icon left class="white--text" size="22">mdi-home</v-icon>
        </span>
        <p class="mt-4 white--text text-none pt-1">{{ $t("blog.news") }}</p>
      </v-btn>
      <v-btn :to="`/${$i18n.locale}/blog/educatie`" text>
        <span class="white--text">
          <v-icon left class="white--text" size="22">mdi-school</v-icon>
        </span>
        <p class="mt-4 white--text text-none pt-1">
          {{ $t("blog.education") }}
        </p>
      </v-btn>
      <v-btn :to="`/${$i18n.locale}/blog/jocuri-video`" text>
        <span class="white--text">
          <v-icon left class="white--text" size="22">mdi-gamepad</v-icon>
        </span>
        <p class="mt-4 white--text text-none pt-1">{{ $t("blog.gaming") }}</p>
      </v-btn>
    </v-toolbar-items>
    <v-toolbar-items>
      <v-btn
        :to="`/${$i18n.locale}`"
        text
        v-if="route === 'homeView'"
        class="hidden-xs-only"
      >
        <span class="white--text">
          <v-icon left class="white--text" size="22">mdi-home</v-icon>
        </span>

        <p class="mt-5 white--text text-none hidden-xs-only">
          {{ $t("nav.home") }}
        </p>
      </v-btn>
      <v-tooltip bottom nudge-top="5" style="z-index: 1">
        <template v-slot:activator="{ on }">
          <v-btn
            :to="`/${$i18n.locale}/portfolio`"
            text
            v-on="on"
            class="hidden-xs-only"
          >
            <span class="white--text">
              <v-icon left class="white--text" size="22"
                >mdi-folder-text-outline</v-icon
              >
            </span>
            <p class="mt-5 white--text text-none hidden-xs-only">
              {{ $t("nav.portfolio") }}
            </p>
          </v-btn>
        </template>
        <span>{{ $t("nav.portfolioText") }}</span>
      </v-tooltip>
    </v-toolbar-items>
  </v-toolbar-items>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "NavLinks",
  data() {
    return {};
  },
  computed: {
    ...mapState(["route"]),
  },
  methods: {},
};
</script>
