<template>
  <v-menu offset-y class="mr-2">
    <template v-slot:activator="{ on }">
      <v-btn text dark v-on="on" @click="show = !show">
        <v-avatar size="25" class="mr-3">
          <v-img
            cover
            :src="require('@/assets/flags/' + $i18n.locale + '.svg')"
            alt="alt"
          ></v-img>
        </v-avatar>

        <v-icon color="white">{{
          show ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>
    </template>

    <v-list style="padding: 0px">
      <v-list-item-group>
        <v-list-item
          v-for="(lang, i) in $i18n.availableLocales"
          :key="`Lang${i}`"
          @click="setLanguageLocale(lang)"
        >
          <v-avatar size="25" class="mr-3">
            <v-img
              cover
              :src="require('@/assets/flags/' + lang + '.svg')"
              alt="alt"
            ></v-img>
          </v-avatar>
          <span class="text-uppercase">{{ lang }}</span>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "LanguageSwitcherComponent",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    setLanguageLocale(lang) {
      this.$i18n.locale = lang;
      this.$router.push({
        params: { lang: lang },
      });
    },
  },
};
</script>
