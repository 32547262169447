<template>
  <v-overlay
    :value="overlay"
    :opacity="opacity"
    color="grey"
    style="z-index: 10"
  >
    <v-progress-circular
      indeterminate
      color="black"
      size="100"
    ></v-progress-circular>
  </v-overlay>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "OverlayView",
  data() {
    return {
      opacity: 0.9,
    };
  },
  computed: {
    ...mapState(["overlay"]),
  },
  created() {
    this.$store.commit("SET_OVERLAY");
  },

  methods: {},
};
</script>
