<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item-title v-bind="attrs" v-on="on" class="pt-1">
        <v-icon size="30" left color="purple">mdi-login-variant</v-icon>
        <span class="mt-10">Login</span>
      </v-list-item-title>
    </template>

    <v-card>
      <v-form v-model="isValid" class="pb-0">
        <v-container fluid grid-list-xs class="ma-0 pa-0">
          <v-row justify="center" class="ma-0 pa-0">
            <v-card
              color="purple"
              height="220px"
              tile
              width="100%"
              class="py-5"
            >
              <v-row justify="center" align="center" class="ma-0 pa-0">
                <v-avatar size="100" class="mr-3">
                  <v-img
                    :src="logoUrl"
                    height="300"
                    max-width="auto"
                    alt="Profile Photo"
                  />
                </v-avatar>
              </v-row>
              <v-row justify="center" class="ma-0">
                <h1 class="white--text mb-5">Eduprivate</h1></v-row
              >
            </v-card>
          </v-row>
          <v-row justify="center" class="ma-0 pa-0" v-if="!toggleBetween">
            <v-col cols="9" class="py-0 mt-8">
              <v-text-field
                label="Email*"
                :rules="[required('Email'), emailVal(email)]"
                v-model="email"
                color="purple"
              ></v-text-field>
            </v-col>

            <v-col cols="9" class="py-0">
              <v-text-field
                v-model="password"
                :rules="[required('Parola')]"
                color="purple"
                type="password"
                label="Parola"
                @keyup.enter="login()"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="7" sm="4" class="pb-10">
              <v-btn
                large
                @click="login()"
                color="purple"
                :disabled="!isValid"
                class="custom-transform-class text-none white--text ml-5"
              >
                <h4>Logheaza-te</h4>
              </v-btn>
            </v-col>
            <!-- <v-col cols="10">
          <v-divider></v-divider>
        </v-col> -->
            <!-- <v-col cols="5" sm="3" class="mt-3">
          <p>Nu ai inca cont?</p>
        </v-col>
        <v-col cols="5" sm="3">
          <v-btn
            small
            @click="toggleBetween = !toggleBetween"
            color="grey"
            class="custom-transform-class text-none pushBtn"
            >Inregistreaza-te</v-btn
          >
        </v-col> -->
          </v-row>
        </v-container>
        <!-- <v-row justify="center" class="ma-0 pa-0" v-if="toggleBetween">
        <v-col cols="9" class="py-0 mt-8">
          <v-text-field
            label="Username*"
            @change="verifyUsername()"
            :rules="[
              required('Username'),
              minLength('Username', 4),
              maxLength('Username', 30),
            ]"
            :error-messages="events.length ? ['Username-ul exista deja'] : []"
            v-model="username"
            color="purple"
          ></v-text-field>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-text-field
            label="Email*"
            :rules="[required('Email'), emailVal(email)]"
            @change="verifyEmail()"
            v-model="email"
            :error-messages="emails.length ? ['Email-ul exista deja'] : []"
            color="purple"
          ></v-text-field>
        </v-col>

        <v-col cols="9" class="py-0">
          <v-text-field
            v-model="password"
            :rules="[
              requiredPass('Parola'),
              passwordConfirmationRule(),
              passwordVal(password),
            ]"
            color="purple"
            type="password"
            label="Parola*"
            @keyup.enter="register"
            required
          ></v-text-field>
          <v-text-field
            v-model="rePassword"
            :rules="[
              requiredPass('Parola'),
              passwordConfirmationRule(),
              passwordVal(rePassword),
            ]"
            color="purple"
            type="password"
            label="Confirma parola*"
            @keyup.enter="register"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="7" sm="4">
          <v-btn
            large
            @click="register"
            color="purple"
            :disabled="!isValid"
            class="custom-transform-class text-none white--text ml-5"
          >
            <h4>Creaza cont</h4>
          </v-btn>
        </v-col>
        <v-col cols="10">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="7" sm="4" class="mt-3">
          <p>Ai cont deja inregistrat?</p>
        </v-col>
        <v-col cols="4" sm="3">
          <v-btn
            small
            @click="toggleBetween = !toggleBetween"
            color="grey"
            class="custom-transform-class text-none pushBtn"
            >Logheaza-te</v-btn
          >
        </v-col>
      </v-row> -->
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { fb, db } from "@/firebase";
import { mapState } from "vuex";
import logo from "../../assets/owlearningWhite.png";

export default {
  name: "LoginView",

  data() {
    return {
      isValid: false,
      dialog: false,
      overlay: true,
      logoUrl: logo,
      toggleBetween: false,
      events: [],
      emails: [],
      username: "",
      email: "",
      password: "",
      rePassword: "",

      required(prototypeType) {
        return (v) => (v && v.length > 0) || `${prototypeType}-ul este necesar`;
      },
      requiredPass(prototypeType) {
        return (v) => (v && v.length > 0) || `${prototypeType} este necesara`;
      },
      passwordConfirmationRule: () => {
        return () =>
          this.password === this.rePassword || "Parola trebuie sa fie aceeasi";
      },
      maxLength(prototypeType, maxLength) {
        return (v) =>
          (v && v.length <= maxLength) ||
          `${prototypeType}-ul trebuie sa aiba maxim ${maxLength} de litere`;
      },
      passwordVal: (value) => {
        const pattern =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
        return (
          pattern.test(value) ||
          "Min. 8 caractere cu cel putin o majuscula, un numar si un caracter special"
        );
      },
      minLength(prototypeType, minLength) {
        return (v) =>
          (v && v.length >= minLength) ||
          `${prototypeType}-ul trebuie sa aiba minim ${minLength} litere`;
      },
      emailVal: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email-ul trebuie sa fie valid.";
      },
    };
  },
  computed: {
    ...mapState(["profileUserId"]),
  },
  methods: {
    verifyUsername() {
      db.collection("accounts")
        .where("username", "==", this.username)
        .onSnapshot((docSnapshot) => {
          let events = [];
          docSnapshot.forEach(function (doc) {
            let appData = doc.data();
            appData.lid = doc.id;
            events.push(appData);
          });
          this.events = events;
        });
    },
    verifyEmail() {
      db.collection("accounts")
        .where("email", "==", this.email)
        .onSnapshot((docSnapshot) => {
          let events = [];
          docSnapshot.forEach(function (doc) {
            let appData = doc.data();
            appData.lid = doc.id;
            events.push(appData);
          });
          this.emails = events;
        });
    },
    login() {
      fb.auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode === "auth/wrong-password") {
            alert("Parola gresita.");
          } else {
            alert(errorMessage);
          }
        });
    },
    register() {
      fb.auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(() => {
          const profileId = this.profileUserId;
          let newAccount = {
            accountType: "User",
            email: this.email,
            username: this.username,
            profilePhoto: "",
            affiliated: "",
            permissions: [],
            city: "",
            address: "",
            socials: {
              facebook: "",
              instagram: "",
              twitter: "",
              youtube: "",
            },
            userId: profileId,
          };

          db.collection("accounts").doc(profileId).set(newAccount);
          this.$router.replace(`/users/${profileId}`);
        });
    },
  },
};
</script>
<style scoped>
.changeBg {
  background-color: #6d1bff;
}
:deep(.v-text-field__details) {
  display: initial !important;
}
:deep(.v-messages.theme--light) {
  display: initial !important;
}
.pushBtn {
  margin-top: 8px;
}
:deep(.changeBtn) {
  background-color: black !important;
}
</style>
