<template>
  <v-toolbar-items>
    <!-- Messages area for profiles -->
    <v-menu
      offset-y
      v-if="
        msgShow &&
        user &&
        profileType === 'School' &&
        subscription !== 'default'
      "
    >
      <template v-slot:activator="{ on }">
        <v-btn small text link v-on="on">
          <v-badge :content="messages.length" color="pink" overlap>
            <span class="white--text ml-2">
              <v-icon left class="white--text mt-1">mdi-forum</v-icon>
            </span>
          </v-badge>
        </v-btn>
      </template>
      <v-list width="230px">
        <div v-for="(item, i) in messages" :key="i">
          <v-list-item @click.prevent v-if="i <= 3">
            <v-row class="px-5 py-2">
              <router-link
                class="router-link"
                :to="`/messages/${profileUserId}`"
              >
                <v-col cols="12">
                  <v-list-item-title class="black--text"
                    >{{ item.last }} {{ item.first }}</v-list-item-title
                  >
                </v-col>
                <v-col cols="12">
                  <h6 class="black--text">
                    {{ item.date }}
                  </h6>
                </v-col>
              </router-link>
            </v-row>
          </v-list-item>
        </div>

        <v-divider v-if="messages.length" class="mt-3 mb-1"></v-divider>

        <v-list-item @click.prevent>
          <v-row justify="center">
            <router-link
              class="router-link"
              :to="`/${$i18n.locale}/messages/${profileUserId}`"
            >
              <v-icon large left class="purple--text">mdi-message-cog</v-icon>
              <span class="text-none purple--text">Vezi toate mesajele</span>
            </router-link>
          </v-row>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      offset-y
      v-if="
        !msgShow &&
        user &&
        profileType === 'School' &&
        subscription !== 'default'
      "
    >
      <template v-slot:activator="{ on }">
        <v-btn small text link v-on="on">
          <span class="white--text ml-2">
            <v-icon left class="white--text mt-1">mdi-forum</v-icon>
          </span>
        </v-btn>
      </template>
      <v-list width="230px">
        <v-divider v-if="messages.length" class="mt-3 mb-1"></v-divider>

        <v-list-item v-if="!messages.length">
          <v-row justify="center">
            <h5>Nu exista mesaje noi</h5>
          </v-row>
        </v-list-item>
        <v-list-item @click.prevent>
          <v-row justify="center">
            <router-link
              class="router-link"
              :to="`/${$i18n.locale}/messages/${profileUserId}`"
            >
              <v-icon large left class="purple--text">mdi-message-cog</v-icon>
              <span class="text-none purple--text">Vezi toate mesajele</span>
            </router-link>
          </v-row>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- Admin messages -->
    <v-menu offset-y v-if="msgShow && user && profileType === 'Admin'">
      <template v-slot:activator="{ on }">
        <v-btn small text link v-on="on">
          <v-badge :content="adminMessages.length" color="pink" overlap>
            <span class="white--text ml-2">
              <v-icon left class="white--text">mdi-forum</v-icon>
            </span>
          </v-badge>
        </v-btn>
      </template>
      <v-list width="230px">
        <div v-for="(item, i) in adminMessages" :key="i">
          <v-list-item @click.prevent v-if="i <= 3">
            <v-row class="px-5 py-2">
              <router-link
                class="router-link"
                :to="`/${$i18n.locale}/messages/${profileUserId}`"
              >
                <v-col cols="12">
                  <v-list-item-title class="black--text"
                    >{{ item.last }} {{ item.first }}</v-list-item-title
                  >
                </v-col>
                <v-col cols="12">
                  <h6 class="black--text">
                    {{ item.date }}
                  </h6>
                </v-col>
              </router-link>
            </v-row>
          </v-list-item>
        </div>

        <v-divider v-if="msgShow" class="mt-3 mb-1"></v-divider>

        <v-list-item @click.prevent>
          <v-row justify="center">
            <router-link class="router-link" :to="`/messages/${profileUserId}`">
              <v-icon large left class="purple--text">mdi-message-cog</v-icon>
              <span class="text-none purple--text">Vezi toate mesajele</span>
            </router-link>
          </v-row>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu offset-y v-if="!msgShow && user && profileType === 'Admin'">
      <template v-slot:activator="{ on }">
        <v-btn small text link v-on="on" v-if="!msgShow">
          <span class="white--text ml-2">
            <v-icon left class="white--text">mdi-forum</v-icon>
          </span>
        </v-btn>
      </template>
      <v-list width="230px">
        <v-list-item>
          <v-row justify="center">
            <h5>Nu exista mesaje noi</h5>
          </v-row>
        </v-list-item>
        <v-list-item @click.prevent>
          <v-row justify="center">
            <router-link
              class="router-link"
              :to="`/${$i18n.locale}/messages/${profileUserId}`"
            >
              <v-icon large left class="purple--text">mdi-message-cog</v-icon>
              <span class="text-none purple--text">Vezi toate mesajele</span>
            </router-link>
          </v-row>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar-items>
</template>

<script>
import { db } from "@/firebase";
import { mapState } from "vuex";

export default {
  name: "MessagesNav",
  data() {
    return {
      show: false,
      msgShow: false,
      messages: [],
      adminMessages: [],
    };
  },
  computed: {
    ...mapState(["profileType", "user", "profileUserId"]),
  },
  watch: {
    adminMessages: {
      handler: function (newValue) {
        if (newValue.length) {
          this.msgShow = true;
        } else {
          this.msgShow = false;
        }
      },
    },
    messages: {
      handler: function (newValue) {
        if (newValue.length) {
          this.msgShow = true;
        } else {
          this.msgShow = false;
        }
      },
    },
  },
  created() {
    if (this.user) {
      if (this.profileType === "Admin") {
        this.getAdminMessages();
      } else {
        this.getMessages();
      }
    }
  },
  methods: {
    getMessages() {
      db.collection("messages")
        .where("userId", "==", this.profileUserId)
        .where("messageType", "==", "Inscriere")
        .where("status", "==", "unread")
        .orderBy("date", "desc")
        .onSnapshot((docSnapshot) => {
          let events = [];
          docSnapshot.forEach((doc) => {
            let appData = doc.data();
            events.push(appData);
          });
          this.messages = events;
        });
    },

    getAdminMessages() {
      db.collection("messages")
        .where("messageType", "==", "Contact")
        .where("status", "==", "unread")
        .orderBy("date", "desc")
        .onSnapshot((docSnapshot) => {
          let events = [];
          docSnapshot.forEach((doc) => {
            let appData = doc.data();
            events.push(appData);
          });
          this.adminMessages = events;
        });
    },
  },
};
</script>
<style scoped>
.img {
  display: block;
  position: absolute;
  top: 100px;
}
:deep(.v-list-item-group .v-list-item--active) {
  background-color: transparent !important;
  color: transparent;
}
</style>
