<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-click="false"
      :close-on-content-click="false"
      :open-on-click="false"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :label="$t('search.text')"
          v-model="searchInput"
          solo
          @input="searchSuggestions()"
          v-on:focus="menu = true"
          v-on:blur="menu = true"
          v-on="on"
          :class="{
            searchBarXs: $vuetify.breakpoint.xs && route !== 'homeView',
            searchBarSm: $vuetify.breakpoint.smAndUp && route !== 'homeView',
            searchBarHomeSm:
              $vuetify.breakpoint.smAndUp && route === 'homeView',
          }"
          color="purple"
          ><template v-slot:append v-if="route === 'homeView'">
            <v-fade-transition leave-absolute>
              <v-btn color="purple" class="white--text">
                <v-icon class="mr-1">mdi-map-marker</v-icon>
                {{ $t("button.search") }}</v-btn
              >
            </v-fade-transition>
          </template>
        </v-text-field>
      </template>

      <v-card dense v-if="!search">
        <v-tabs v-model="tab" color="purple">
          <v-tabs-slider></v-tabs-slider>
          <v-tab>
            <v-icon class="mr-2">mdi-map-marker-multiple</v-icon>
            <span class="text-none">{{ $t("search.locations") }}</span>
          </v-tab>
          <v-tab>
            <v-icon class="mr-2">mdi-account</v-icon>
            <span class="text-none">{{ $t("search.profiles") }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-list rounded dense v-if="!showSuggestion && searchInput === ''">
              <v-list-group
                prepend-icon="mdi-map-marker-multiple"
                no-action
                color="purple"
                :value="false"
                v-for="(suggestion, i) in suggestionsGroup"
                :key="'A' + i"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{ suggestion.name }}</v-list-item-title>
                </template>

                <v-list-item
                  color="purple"
                  v-for="(area, i) in suggestion.areas"
                  :key="'B' + i"
                  link
                  @click="routeResolve(area), (menu = false)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-map-marker</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ area }}</v-list-item-title>
                </v-list-item>
              </v-list-group>
              <v-list-item-group no-action :value="false">
                <v-list-item
                  color="purple"
                  v-for="(suggestion, i) in suggestions"
                  :key="'B' + i"
                  @click="routeResolve(suggestion), (menu = false)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-map-marker</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    suggestion
                  }}</v-list-item-title></v-list-item
                >
              </v-list-item-group>
            </v-list>
            <v-list rounded dense v-else>
              <v-list-group
                prepend-icon="mdi-map-marker-multiple"
                no-action
                color="purple"
                :value="true"
                v-for="(suggestion, i) in filteredSuggestions"
                :key="'C' + i"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{ suggestion.name }}</v-list-item-title>
                </template>
                <v-list-item
                  color="purple"
                  v-show="!suggestion.filteredAreas.length"
                  v-for="(area, i) in suggestion.areas"
                  :key="'D' + i"
                  link
                  @click="routeResolve(area), (menu = false)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-map-marker</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ area }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  color="purple"
                  v-show="suggestion.filteredAreas.length"
                  v-for="(area, i) in suggestion.filteredAreas"
                  :key="'E' + i"
                  link
                  @click="routeResolve(area), (menu = false)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-map-marker</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ area }}</v-list-item-title>
                </v-list-item>
              </v-list-group>

              <v-list-item v-if="!filteredSuggestions.length">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("search.noResultsFirst") }} "<strong>{{
                      searchInput
                    }}</strong
                    >" {{ $t("search.noResultsLast") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
          </v-tab-item>

          <v-tab-item>
            <v-list dense rounded>
              <v-list-item-group @click="menu = false">
                <v-list-item
                  v-show="!schools.length"
                  v-for="(school, i) in schools"
                  :key="'F' + i"
                  :to="`/${$i18n.locale}/${school.username}/`"
                  @click="(menu = false), GET_SCHOOL_ACCOUNT(school.username)"
                >
                  <v-list-item-avatar>
                    <v-img contain :src="school.profilePhoto"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ school.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-show="filteredSchools.length"
                  v-for="(school, i) in filteredSchools"
                  :key="'G' + i"
                  :to="`/${$i18n.locale}/${school.username}/`"
                  @click="(menu = false), GET_SCHOOL_ACCOUNT(school.username)"
                >
                  <v-list-item-avatar>
                    <v-img contain :src="school.profilePhoto"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ school.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!filteredSchools.length">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("search.noResultsFirst") }} "<strong>{{
                        searchInput
                      }}</strong
                      >" {{ $t("search.noResultsLast") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-divider></v-divider>
          </v-tab-item>
        </v-tabs-items>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="menu = false"> {{ $t("button.close") }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "SearchBar",
  data() {
    return {
      dialog: false,
      tab: 0,
      showSuggestion: false,
      pickSuggestion: "",
      isActive: false,
      disableBtn: false,
      category: "Toate",
      menu: false,
      location: this.$route.params.id2,
      classType: "",
      classCategories: [{ name: "Online", group: "Tip curs" }],
      searchFocus: false,
      searchResults: [],
      searchProfiles: [],
      subjects: [
        { header: "IT" },
        { divider: true },
        { name: "C++", group: "Programare" },
        { name: "HTML", group: "Web development" },
        { name: "CSS", group: "Web development" },
        { name: "Javascript", group: "Web development" },
        { name: "PHP", group: "Web development" },
        { name: "MySQL", group: "Web development" },
      ],
      subject: "",
      ages: [
        { name: "Adulti", group: "Curs destinat adultilor" },
        { divider: true },
        { name: "Copii", group: "Curs destinat copiilor" },
        { divider: true },
      ],
      age: "Adulti",
      difficulties: [
        { name: "Pentru toti", group: "Acces tuturor interesati" },
        { divider: true },
        { name: "Incepator", group: "Cunostiinte minime" },
        { divider: true },
        { name: "Intermediar", group: "Cunostiinte medii si o baza buna" },
        { divider: true },
        { name: "Avansat", group: "Cunostiinte avansate si o intelegere buna" },
      ],
      options: ["Toate", "Gradinite", "Scoli"],
      option: "Toate",
      categories: ["IT"],
      select: "IT",
      searchInput: "",
      filteredSuggestions: [],
      filteredSchools: [],
      suggestions: [
        // "Brasov",
        "Cluj-Napoca",
        // "Constanta",
        // "Iasi",
        // "Sibiu",
        // "Timisoara",
      ],
      suggestionsGroup: [
        {
          name: "Ilfov",
          filteredAreas: [],
          areas: ["Comuna Corbeanca"],
        },
        {
          name: "Bucuresti",
          filteredAreas: [],
          areas: [
            "Bucuresti",
            "Sector 1",
            "Sector 2",
            "Sector 3",
            "Sector 4",
            "Sector 5",
            "Sector 6",
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState([
      "profileUserId",
      "searchCategory",
      "searchInput",
      "searchedSchools",
      "schools",
      "route",
    ]),
  },
  watch: {
    search: {
      handler: function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.showSuggestion = true;
          this.searchResults = [];
        }
      },
    },
  },
  created() {
    this.GET_SCHOOLS();
  },
  methods: {
    ...mapActions([
      "GET_SEARCHED_SCHOOLS",
      "GET_SCHOOLS",
      "GET_SCHOOL_ACCOUNT",
    ]),
    routeResolve(location) {
      this.$store.commit("SET_SEARCH_INPUT", location);
      this.GET_SEARCHED_SCHOOLS(location);
      let currentLocation = "";
      if (
        this.searchCategory === "Gradinite" ||
        this.searchCategory === "Scoli" ||
        this.searchCategory === "Toate"
      ) {
        currentLocation =
          "/" +
          this.$i18n.locale +
          "/gradinite-scoli/" +
          this.searchCategory +
          "/" +
          location;
      } else if (this.category === "Cluburi") {
        currentLocation =
          "/" + this.$i18n.locale + "/cluburi/" + this.select + "/" + location;
      }

      if (currentLocation !== this.$route.fullPath) {
        this.$router.push({ path: currentLocation });
      }
    },

    searchSuggestions() {
      this.filteredSuggestions = [];
      this.filteredSchools = [];
      this.suggestionsGroup.forEach((option) => {
        let name = option.name;
        if (
          name.toLowerCase().includes(this.searchInput.toLowerCase()) &&
          !this.filteredSuggestions.some(
            (suggestion) => suggestion.name === option.name
          )
        ) {
          this.filteredSuggestions.push(option);
        } else {
          let newAreas = [];
          let itemsProcessed = 0;

          option.areas.forEach((area) => {
            itemsProcessed = itemsProcessed++;
            if (area.toLowerCase().includes(this.searchInput.toLowerCase())) {
              newAreas.push(area);
            }
          });
          option.filteredAreas = newAreas;
          if (option.areas.includes(option.filteredAreas[0])) {
            this.filteredSuggestions.push(option);
          }
        }
      });

      this.schools.forEach((school) => {
        let name = school.name;
        if (
          name.toLowerCase().includes(this.searchInput.toLowerCase()) &&
          !this.filteredSchools.some(
            (suggestion) => suggestion.name === school.name
          )
        ) {
          this.filteredSchools.push(school);
        }
      });
    },
  },
};
</script>

<style scoped>
:deep(
    .searchBarHomeSm.v-text-field.v-text-field--solo:not(
        .v-text-field--solo-flat
      )
      > .v-input__control
      > .v-input__slot
  ) {
  padding: 18px;
  border-radius: 15px;
}
:deep(.v-messages.theme--light) {
  display: none !important;
}
:deep(.v-text-field__details) {
  display: none !important;
}

.v-text-field--filled {
  background: white;
  color: white;
}
:deep(.theme--dark.v-card) {
  background: white !important;
  color: F0F0F0;
}
.v-list {
  height: 35vh; /* or any height you want */
  overflow-y: auto;
}
.theme--dark.v-icon {
  color: #6d1bff;
}

:deep(.v-text-field.v-text-field--solo .v-input__control input) {
  color: black !important;
}
:deep(
    .theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
        .v-btn--outlined
      )
  ) {
  background-color: #f0f0f0 !important;
  color: black !important;
}
:deep(
    .theme--dark.v-btn.v-btn--disabled .v-icon,
    .theme--dark.v-btn.v-btn--disabled .v-btn__loading
  ) {
  color: grey !important;
}
.searchBarXs {
  width: 100%;
  margin-top: 8px;
}
.searchBarSm {
  width: 600px;
  margin-top: 8px;
}
.searchBarHomeSm {
  width: 700px;
  margin-top: 8px;
}
</style>
