<template>
  <v-app>
    <v-header style="z-index: 1">
      <v-nav>
        <DefaultNav />
      </v-nav>
    </v-header>

    <v-main
      :class="
        route === 'homeView' || route === 'schoolView' ? 'py-0' : 'pushNav py-0'
      "
    >
      <!-- Provides the application the proper gutter -->
      <v-container
        fluid
        grid-list-xs
        class="ma-0 pa-0"
        style="min-height: 85vh"
      >
        <v-row
          justify="center"
          class="ma-0 pa-0 mt-2 hidden-sm-and-up"
          v-show="
            route === 'blogView' ||
            route === 'articleView' ||
            route === 'blogEducation' ||
            route === 'blogGaming'
          "
        >
          <v-sheet class="">
            <v-slide-group dense center-active hide-arrows>
              <v-slide-item>
                <v-btn :to="`/${$i18n.locale}/blog/news`" text>
                  <v-icon left class="black--text" size="22">mdi-home</v-icon>
                  <p class="mt-4 black--text text-none pt-1">
                    {{ $t("blog.news") }}
                  </p>
                </v-btn>
              </v-slide-item>
              <v-slide-item>
                <v-btn :to="`/${$i18n.locale}/blog/educatie`" text>
                  <v-icon left class="black--text" size="22">mdi-school</v-icon>
                  <p class="mt-4 black--text text-none pt-1">
                    {{ $t("blog.education") }}
                  </p>
                </v-btn>
              </v-slide-item>
              <v-slide-item>
                <v-btn :to="`/${$i18n.locale}/blog/jocuri-video`" text>
                  <v-icon left class="black--text" size="22"
                    >mdi-gamepad</v-icon
                  >
                  <p class="mt-4 black--text text-none pt-1">
                    {{ $t("blog.gaming") }}
                  </p>
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-row>
        <!-- If using vue-router -->
        <template functional style="background: white">
          <Overlay />
          <router-view :key="$route.fullPath"></router-view>
        </template>
      </v-container>
    </v-main>
    <v-lazy
      :options="{
        threshold: 0.5,
      }"
      transition="fade-transition"
    >
      <FooterView
        style="z-index: 2"
        v-show="
          route !== 'schools' &&
          route !== 'blog' &&
          route !== 'clubs' &&
          route !== 'filter' &&
          route !== 'article' &&
          route !== 'hub' &&
          route !== 'adminArticles' &&
          route !== 'articlePreview' &&
          route !== 'profiles' &&
          route !== 'studio' &&
          route !== 'settings'
        "
      />
    </v-lazy>
  </v-app>
</template>

<script>
import DefaultNav from "./components/navigation/_DefaultNav";
import Overlay from "./components/Overlay";
import FooterView from "./components/footer/Footer";
import { fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
export default {
  name: "App",
  metaInfo() {
    return {
      title:
        "Gradinite si scoli private din Bucuresti si alte orase - eduprivate.com",
      meta: [
        {
          name: "descriere",
          content:
            "Gaseste gradinite si scoli din Bucuresti, Cluj-Napoca, Timisoara, Brasov, Constanta, Iasi, Sibiu. Design modern pentru o problema moderna. O multime de functionalitati ce te ajuta sa iti construiesti un viitor important alaturi de familia ta! Oferai copilului tau cea mai buna educatie posibila in sectorul de gradinite private, cat si cel de scoli private din Romania.",
        },
        {
          property: "og:title",
          content:
            "Gradinite si scoli private din Bucuresti si alte orase - eduprivate.com",
        },
        { property: "og:site_name", content: "eduprivate.com" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  components: {
    DefaultNav,
    FooterView,
    Overlay,
  },
  data() {
    return {
      show: false,
    };
  },

  computed: {
    ...mapState(["route", "profileAdmin"]),
  },

  created() {
    this.show = true; // might need this.$nextTick

    fb.auth().onAuthStateChanged(async (user) => {
      this.$store.commit("UPDATE_USER", user);
      if (user) {
        this.GET_CURRENT_PROFILE(user);
      }
    });

    this.GET_ARTICLES();
  },
  methods: {
    ...mapActions(["GET_CURRENT_PROFILE", "GET_ARTICLES"]),
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");

html,
body {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}

#app {
  font-family: "Nunito", sans-serif;
}
.v-row {
  margin: 0px !important;
  padding: 0px !important;
}
.pushNav {
  margin-top: 64px;
}
.pushTop {
  margin-top: 150px;
}
.sectDistance {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.v-slide-group__prev {
  display: none !important;
}
.v-btn.v-btn--depressed.v-btn--fab.v-btn--round.theme--light.v-size--small {
  height: 28px;
  width: 30px;
}
.pointer {
  cursor: pointer;
}
.v-slide-group__next {
  display: none !important;
}

.v-treeview-node--leaf > .v-treeview-node__root {
  padding-left: 0px !important;
}
.v-treeview-node.v-treeview-node--leaf.v-treeview-node--click {
  height: 45px !important;
}
.v-treeview-node__root {
  min-height: 35px !important;
}
.rounded-card {
  border-radius: 14px !important;
}
.headline {
  color: #0a1a49;
}
.margSecTop {
  padding-top: 70px;
  padding-bottom: 70px;
}
.v-application--wrap {
  min-height: 0vh !important;
}
.router-link {
  text-decoration: none;
}
.img-wrap {
  position: relative;
}

.img-wrap span.delete-img {
  position: absolute;
  top: 1px;
  left: 10px;
  color: white;
  background: black;
  padding: 0px 10px;
}

.img-wrap span.delete-img:hover {
  cursor: pointer;
}
tbody tr:nth-of-type(even) {
  background-color: #f5f5f5;
}

tbody tr:nth-of-type(odd) {
  background-color: white;
}
</style>
