<template>
  <v-footer
    :color="
      route === 'blogView' || paramsId === 'articleView' ? 'green' : 'purple'
    "
  >
    <v-container fluid grid-list-xs>
      <v-col cols="11" sm="5" class="ma-0 pa-0 mt-14">
        <v-row justify="center">
          <v-chip
            color="white"
            label
            class="pa-6"
            :to="`/${$i18n.locale}/blog`"
          >
            <h1 class="black--text">
              {{ $t("footer.articlesTitle") }}
            </h1></v-chip
          >
        </v-row>
      </v-col>
      <v-row justify="center" class="pb-10 pt-5">
        <v-col cols="10" sm="3" class="mx-5">
          <v-row class="mt-4">
            <v-btn
              text
              color="white"
              class="ml-6 text-decoration-underline"
              :to="`/${$i18n.locale}/blog/educatie`"
              @click.prevent="$vuetify.goTo('#top', { offset: 50 })"
              ><h3>{{ $t("footer.articlesCategory") }}</h3>
            </v-btn>
            <v-col
              cols="12"
              v-for="(article, i) in educationArticles"
              :key="'A' + i"
            >
              <router-link
                class="router-link pointer"
                :to="`/${$i18n.locale}/blog/${article.docId}`"
                link
              >
                <v-row class="ma-0 pa-0">
                  <v-col cols="11" sm="5" class="mt-2">
                    <v-img
                      :src="article.articlePhoto"
                      class="rounded-card"
                      height="130px"
                      width="auto"
                      :class="{
                        xsWidth: $vuetify.breakpoint.xs,
                        smWidth: $vuetify.breakpoint.smAndUp,
                      }"
                      alt="article photo"
                    ></v-img>
                  </v-col>
                  <v-col cols="11" sm="7" class="mt-6">
                    <v-row
                      align="center"
                      style="height: 125px"
                      class="align-content-space-between"
                    >
                      <h4 class="white--text pl-3 mt-3">
                        {{ article.title }}
                      </h4>
                      <v-row justify="space-between" class="pl-6 mb-1">
                        <v-chip
                          label
                          dark
                          outlined
                          class="dateTxt grey--text"
                          v-if="article.date"
                          >{{ article.date }}</v-chip
                        >
                        <v-chip
                          label
                          small
                          outlined
                          color="white"
                          class="white--text mb-2"
                          >{{ $t("footer.articlesCategory") }}</v-chip
                        >
                      </v-row>
                    </v-row>
                  </v-col>
                </v-row>
              </router-link>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="10" sm="3" class="mx-5">
          <!-- <v-row class="mt-4">
            <v-chip
              label
              color="white"
              class="ml-8"
              to="/blog"
              @click.prevent="$vuetify.goTo('#top', { offset: 50 })"
              ><h3>Cursuri</h3></v-chip
            >
            <v-col
              cols="12"
              v-for="(article, i) in coursesArticles"
              :key="'B' + i"
            >
              <router-link
                class="router-link pointer"
                :to="`/blog/${article.docId}`"
                link
              >
                <v-row class="ma-0 pa-0">
                  <v-col cols="11" sm="5" class="mt-5">
                    <v-img
                      :src="article.articlePhoto"
                      class="rounded-card"
                      height="130px"
                      width="auto"
                      :class="{
                        xsWidth: $vuetify.breakpoint.xs,
                        smWidth: $vuetify.breakpoint.smAndUp,
                      }"
                      alt="article photo"
                    ></v-img>
                  </v-col>
                  <v-col cols="11" sm="7" class="mt-6">
                    <v-row
                      align="center"
                      style="height: 125px"
                      class="align-content-space-between"
                    >
                      <h4 class="white--text pl-3 mt-3">
                        {{ article.title }}
                      </h4>
                      <v-row justify="space-between" class="mt-4 pl-6">
                        <span class="dateTxt grey--text" v-if="article.date">{{
                          article.date.toDate() | date
                        }}</span>
                        <v-chip
                          small
                          outlined
                          color="white"
                          class="white--text mb-2"
                          >{{ article.category }}</v-chip
                        >
                      </v-row>
                    </v-row>
                  </v-col>
                </v-row>
              </router-link>
            </v-col>
          </v-row> -->
        </v-col>
        <v-col cols="11" sm="2" class="mt-13 pt-3 px-3">
          <v-card class="rounded-card pt-5 pb-8 px-2">
            <v-row class="pl-2 pt-2">
              <h3 class="ml-5 mt-4">{{ $t("footer.navText") }}</h3>
            </v-row>

            <v-row justify="center">
              <v-btn
                :to="`/${$i18n.locale}`"
                @click.prevent="$vuetify.goTo('#overview', { offset: 0 })"
                color="white"
                rounded
                dense
                class="px-3 mx-1 mt-4 text-none"
              >
                {{ $t("nav.home") }}
              </v-btn>
              <v-btn
                :to="`/${$i18n.locale}/blog`"
                @click.prevent="$vuetify.goTo('#top', { offset: 50 })"
                color="white"
                rounded
                dense
                class="px-3 mx-1 mt-4 text-none"
              >
                Blog
              </v-btn>
              <v-btn
                :to="`/${$i18n.locale}/contact`"
                @click.prevent="$vuetify.goTo('#top', { offset: 50 })"
                color="white"
                rounded
                dense
                class="px-3 mx-1 mt-4 text-none"
              >
                {{ $t("nav.contact") }}
              </v-btn>
            </v-row>
            <v-divider class="mt-9 mb-2"></v-divider>

            <v-row class="pl-2 pt-2">
              <h3 class="ml-5 mt-4 mb-2">{{ $t("footer.socials") }}</h3>
            </v-row>

            <v-row class="ml-2 mt-3">
              <v-btn
                v-for="icon in icons"
                :key="icon"
                :href="icon.link"
                dark
                icon
                class="mr-5"
              >
                <v-icon size="24px" color="black">
                  {{ icon.name }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-row justify="center" no-gutters>
      <v-col class="py-4 text-center white--text" cols="12">
        &copy; {{ new Date().getFullYear() }} -
        <strong>{{ $t("footer.rightsReserved") }}</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import { db } from "@/firebase";
import { mapActions, mapState } from "vuex";
export default {
  name: "FooterView",
  computed: {
    ...mapState(["route", "educationArticles"]),
  },
  data() {
    return {
      isActive: false,
      icons: [
        { name: "mdi-facebook", link: "http://www.facebook.com/eduprivatehq" },
        {
          name: "mdi-youtube",
          link: "https://www.youtube.com/channel/UCwsd9AUvrbaEk7MbGtVLblw?view_as=subscriber",
        },
        // { name: "mdi-linkedin", link: "http://www.linkedin.com/eduprivatehq" },
        {
          name: "mdi-instagram",
          link: "http://www.instagram.com/eduprivatehq",
        },
      ],
      coursesArticles: [],
    };
  },

  created() {
    this.GET_EDUCATION_ARTICLES();
  },
  methods: {
    ...mapActions(["GET_EDUCATION_ARTICLES"]),
    getEducationArticles() {
      db.collection("articles")
        .where("category", "==", "educatie")
        .where("published", "==", true)
        .orderBy("date", "desc")
        .limit(3)
        .onSnapshot((docSnapshot) => {
          let events = [];
          docSnapshot.forEach((doc) => {
            let appData = doc.data();
            events.push(appData);
          });
          this.educationArticles = events;
        });
    },
    getCoursesArticles() {
      db.collection("articles")
        .where("category", "==", "Cursuri")
        .where("published", "==", true)
        .orderBy("date", "desc")
        .limit(3)
        .onSnapshot((docSnapshot) => {
          let events = [];
          docSnapshot.forEach((doc) => {
            let appData = doc.data();
            events.push(appData);
          });
          this.coursesArticles = events;
        });
    },
  },
};
</script>
