var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-header',{staticStyle:{"z-index":"1"}},[_c('v-nav',[_c('DefaultNav')],1)],1),_c('v-main',{class:_vm.route === 'homeView' || _vm.route === 'schoolView' ? 'py-0' : 'pushNav py-0'},[_c('v-container',{staticClass:"ma-0 pa-0",staticStyle:{"min-height":"85vh"},attrs:{"fluid":"","grid-list-xs":""}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.route === 'blogView' ||
          _vm.route === 'articleView' ||
          _vm.route === 'blogEducation' ||
          _vm.route === 'blogGaming'
        ),expression:"\n          route === 'blogView' ||\n          route === 'articleView' ||\n          route === 'blogEducation' ||\n          route === 'blogGaming'\n        "}],staticClass:"ma-0 pa-0 mt-2 hidden-sm-and-up",attrs:{"justify":"center"}},[_c('v-sheet',{},[_c('v-slide-group',{attrs:{"dense":"","center-active":"","hide-arrows":""}},[_c('v-slide-item',[_c('v-btn',{attrs:{"to":`/${_vm.$i18n.locale}/blog/news`,"text":""}},[_c('v-icon',{staticClass:"black--text",attrs:{"left":"","size":"22"}},[_vm._v("mdi-home")]),_c('p',{staticClass:"mt-4 black--text text-none pt-1"},[_vm._v(" "+_vm._s(_vm.$t("blog.news"))+" ")])],1)],1),_c('v-slide-item',[_c('v-btn',{attrs:{"to":`/${_vm.$i18n.locale}/blog/educatie`,"text":""}},[_c('v-icon',{staticClass:"black--text",attrs:{"left":"","size":"22"}},[_vm._v("mdi-school")]),_c('p',{staticClass:"mt-4 black--text text-none pt-1"},[_vm._v(" "+_vm._s(_vm.$t("blog.education"))+" ")])],1)],1),_c('v-slide-item',[_c('v-btn',{attrs:{"to":`/${_vm.$i18n.locale}/blog/jocuri-video`,"text":""}},[_c('v-icon',{staticClass:"black--text",attrs:{"left":"","size":"22"}},[_vm._v("mdi-gamepad")]),_c('p',{staticClass:"mt-4 black--text text-none pt-1"},[_vm._v(" "+_vm._s(_vm.$t("blog.gaming"))+" ")])],1)],1)],1)],1)],1),[_c('Overlay'),_c('router-view',{key:_vm.$route.fullPath})]],2)],1),_c('v-lazy',{attrs:{"options":{
      threshold: 0.5,
    },"transition":"fade-transition"}},[_c('FooterView',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.route !== 'schools' &&
        _vm.route !== 'blog' &&
        _vm.route !== 'clubs' &&
        _vm.route !== 'filter' &&
        _vm.route !== 'article' &&
        _vm.route !== 'hub' &&
        _vm.route !== 'adminArticles' &&
        _vm.route !== 'articlePreview' &&
        _vm.route !== 'profiles' &&
        _vm.route !== 'studio' &&
        _vm.route !== 'settings'
      ),expression:"\n        route !== 'schools' &&\n        route !== 'blog' &&\n        route !== 'clubs' &&\n        route !== 'filter' &&\n        route !== 'article' &&\n        route !== 'hub' &&\n        route !== 'adminArticles' &&\n        route !== 'articlePreview' &&\n        route !== 'profiles' &&\n        route !== 'studio' &&\n        route !== 'settings'\n      "}],staticStyle:{"z-index":"2"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }