import Vue from "vue";
import Vuex from "vuex";
import yearNav from "./modules/year-nav.js";
import { db, fb, dbVforce } from "@/firebase";
import axios from "axios";
import moment from "moment";
import router from "../router/index";

Vue.use(Vuex);

//const debug = process.env.NODE_ENV !== 'production'
export default new Vuex.Store({
  modules: {
    yearNav,
  },
  state: {
    user: null,
    profileAdmin: null,
    adminTermsAndConditions: null,
    adminPrivacyPolicy: null,
    profileUsername: null,
    profilePhoto: null,
    profileEmail: null,
    profilePublished: null,
    profileFirstName: null,
    profileLastName: null,
    profileLevels: null,
    profileSocials: null,
    profileJob: null,
    profileDescription: null,
    profileInitials: null,
    profileStreet: null,
    profileStreet_Number: null,
    profileAddress_Area: null,
    profileName: null,
    profileType: null,
    profileGeoPoint: null,
    profileCity: null,
    profilePostal_Code: null,
    profileAddress_Sector: null,
    profileCountry: null,
    profileAfterSchool: null,
    profileLanguages: null,
    profileViewMenu: null,
    profileViewOptionals: null,
    profileViewAdmissions: null,
    profileViewSchedule: null,
    profileViewTeachers: null,
    profileViewAfterSchool: null,
    profileViewCosts: null,
    profileAddress: null,
    profileDirector: null,
    profileUserId: null,
    pageType: null,
    route: null,
    overlay: false,
    currentMonth: moment().format("YYYY-MM"),
    courses: [],
    advertisingSpots: [],
    articles: [],
    educationArticles: [],
    mainArticle: {},
    article: {},
    unpublishedArticles: [],
    articlePhoto: null,
    articleTitle: null,
    articleContent: null,
    articleLink: null,
    articlePromo: null,
    articleCategory: null,
    subscriptions: [],
    subscription: {},
    subscriptionInvoices: [],
    subscriptionsPrices: [],
    subscriptionLoaded: false,
    albums: [],
    albumId: null,
    selectedAlbum: {},
    selectedAlbumIndex: null,
    selectedAlbumPhotos: [],
    albumYears: [],
    albumPhotos: [],
    promotionPhotos: [],
    promotedMain: [],
    promotedTopLeft: [],
    promotedTopRight: [],
    promotedBottomLeft: [],
    promotedBottomRight: [],
    multiplePhotoFiles: [],
    progressBarValue: 0,
    progressBarShow: false,
    foodMenu: {},
    accounts: [],
    allAccountsVisited: [],
    account: {},
    promotedAccounts: [],
    customAccounts: [],
    schools: [],
    searchedSchools: [],
    schoolLevels: ["Cresa", "Gradinita", "Primara", "Secundara"],
    schoolLanguages: ["Engleza", "Franceza", "Spaniola", "Germana", "Chineza"],
    schoolCities: [
      "Bucuresti-Ilfov",
      "Bucuresti",
      "Brasov",
      "Cluj-Napoca",
      "Constanta",
      "Iasi",
      "Sibiu",
      "Timisoara",
      "Corbeanca",
    ],
    schoolSectors: [
      "Sector 1",
      "Sector 2",
      "Sector 3",
      "Sector 4",
      "Sector 5",
      "Sector 6",
    ],
    searchCategory: "Toate",
    searchInput: null,
    userLoaded: false,
    accountLoaded: false,
    menuLoaded: false,
    photosLoaded: false,
  },
  mutations: {
    SET_ROUTE(state, route) {
      state.route = route.name;
    },
    SET_OVERLAY(state) {
      state.overlay = true;
      setTimeout(() => {
        state.overlay = false;
      }, 800);
    },
    SET_PROFILE_ADMIN(state, payload) {
      state.profileAdmin = payload.admin;
      state.profileJob = payload.dbResults.data().job;
      state.profileDescription = payload.dbResults.data().description;
    },
    SET_ADMIN_LEGAL(state, payload) {
      state.adminTermsAndConditions = payload.data().terms;
      state.adminPrivacyPolicy = payload.data().privacy;
    },
    SET_PROFILE_INFO(state, payload) {
      state.profileUserId = payload.id;
      state.profileName = payload.data().name;
      state.profileEmail = payload.data().email;
      state.profileUsername = payload.data().username;
      state.profileType = payload.data().accountType;
      state.profileSocials = payload.data().socials;
      state.profileFirstName = payload.data().firstName;
      state.profileLastName = payload.data().lastName;
      state.profileStreet = payload.data().street;
      state.profileCity = payload.data().city;
      state.profileAddress = payload.data().address;
      state.profileStreet_Number = payload.data().street_number;
      state.profilePostal_Code = payload.data().postal_code;
      state.profileAddress_Sector = payload.data().address_sector;
      state.profileAddress_Area = payload.data().address_area;
      state.profileCountry = payload.data().country;
      state.profileGeoPoint = payload.data().geoPoint;
      state.profileLanguages = payload.data().languages;
      state.profileLevels = payload.data().levels;
      state.profileAfterSchool = payload.data().afterSchool;
      state.profileViewMenu = payload.data().viewMenu;
      state.profileViewOptionals = payload.data().viewOptionals;
      state.profileViewAdmissions = payload.data().viewAdmissions;
      state.profileViewSchedule = payload.data().viewSchedule;
      state.profileViewTeachers = payload.data().viewTeachers;
      state.profileViewAfterSchool = payload.data().viewAfterSchool;
      state.profileViewCosts = payload.data().viewCosts;
      state.profilePhoto = payload.data().profilePhoto;
      state.profileDirector = payload.data().director;
      state.pageType = payload.data().pageType;
      state.promotionPhotos = payload.data().promotionPhotos;
      state.profilePublished = payload.data().published;
    },
    SET_PROFILE_PHOTO(state, payload) {
      state.profilePhoto = payload;
    },

    SET_PROFILE_INITIALS(state) {
      state.profileInitials =
        state.profileFirstName.match(/(\b\S)?/g).join("") +
        state.profileLastName.match(/(\b\S)?/g).join("");
      state.profileName = state.profileLastName + " " + state.profileFirstName;
    },
    UPDATE_USER(state, payload) {
      state.user = payload;
    },
    SET_PROMOTED_ACCOUNTS(state, payload) {
      state.promotedAccounts.push(payload);
    },
    SET_ACCOUNTS(state, payload) {
      state.accounts.push(payload);
    },
    SET_ACCOUNT(state, payload) {
      if (
        !state.allAccountsVisited.some(
          (account) => account.username === payload.username
        )
      ) {
        state.allAccountsVisited.push(payload);
        payload.promotionPhotos.forEach((photo) => {
          if (photo.type === "Main") {
            state.promotedMain = photo.url;
          } else if (photo.type === "TopLeft") {
            state.promotedTopLeft = photo.url;
          } else if (photo.type === "BottomLeft") {
            state.promotedBottomLeft = photo.url;
          } else if (photo.type === "TopRight") {
            state.promotedTopRight = photo.url;
          } else if (photo.type === "BottomRight") {
            state.promotedBottomRight = photo.url;
          }
        });
      }

      return;
    },
    SET_VISITED_ACCOUNT(state, payload) {
      const index = state.allAccountsVisited.findIndex((account) => {
        return account.username === payload;
      });
      state.account = state.allAccountsVisited[index];
      state.account.promotionPhotos.forEach((photo) => {
        if (photo.type === "Main") {
          state.promotedMain = photo.url;
        } else if (photo.type === "TopLeft") {
          state.promotedTopLeft = photo.url;
        } else if (photo.type === "BottomLeft") {
          state.promotedBottomLeft = photo.url;
        } else if (photo.type === "TopRight") {
          state.promotedTopRight = photo.url;
        } else if (photo.type === "BottomRight") {
          state.promotedBottomRight = photo.url;
        }
      });
    },
    SET_FOOD_MENU(state, payload) {
      const index = state.allAccountsVisited.findIndex((account) => {
        return account.username === payload.username;
      });
      state.allAccountsVisited[index].foodMenu = payload.food;
    },
    SET_ALBUMS(state, payload) {
      state.albums = payload.albums;
      state.albumId = payload.docId;
      state.selectedAlbumPhotos = payload.albums[0].photos;
      state.selectedAlbumIndex = 0;
    },
    SET_ALBUM_YEARS(state, payload) {
      state.albumYears.push(payload.year);
    },
    SET_SELECTED_ALBUM(state, payload) {
      state.selectedAlbumPhotos = payload.photos;
      state.selectedAlbumIndex = payload.index;
    },

    SET_UPDATED_ACCOUNT_ALBUM(state, payload) {
      const index = state.albums.findIndex((album) => {
        return album.docId === payload.docId;
      });
      state.albums[index] = payload;
    },
    SET_UPDATED_PRIVACY_POLICY(state, payload) {
      state.adminPrivacyPolicy = payload;
    },

    SET_UPDATED_TERMS_AND_CONDITIONS(state, payload) {
      state.adminTermsAndConditions = payload;
    },
    SET_MULTIPLE_PHOTO_FILES(state, payload) {
      state.multiplePhotoFiles = payload;
    },
    SET_UPDATED_PROGRESS_BAR(state) {
      state.progressBarShow = !state.progressBarShow;
    },
    SET_UPDATED_PROGRESS_BAR_VALUE(state, payload) {
      state.progressBarValue = state.progressBarValue + payload;
    },
    SET_UPDATED_ACCOUNT(state, payload) {
      const index = state.accounts.findIndex((account) => {
        return account.userId === payload.userId;
      });
      state.accounts[index] = payload;
    },
    SET_CUSTOM_ACCOUNTS(state, payload) {
      state.customAccounts.push(payload);
    },
    SET_UPDATED_CUSTOM_ACCOUNT(state, payload) {
      const index = state.customAccounts.findIndex((account) => {
        return account.userId === payload.userId;
      });
      state.customAccounts[index] = payload;
    },
    SET_ARTICLE(state, payload) {
      state.article = payload.data();
    },

    SET_ARTICLE_TITLE(state, payload) {
      state.articleTitle = payload;
    },
    SET_ARTICLE_LINK(state, payload) {
      state.articleLink = payload;
    },
    SET_ARTICLE_PROMO(state, payload) {
      state.articlePromo = payload;
    },
    SET_ARTICLE_CONTENT(state, payload) {
      state.articleContent = payload;
    },
    SET_ARTICLE_CATEGORY(state, payload) {
      state.articleCategory = payload;
    },
    SET_PHOTO_PREVIEW(state, payload) {
      state.articlePhoto = payload;
    },

    SET_ARTICLE_PREVIEW(state, payload) {
      state.articleTitle = payload.articleTitle;
      state.articleContent = payload.articleContent;
      state.articleLink = payload.articleLink;
      state.articlePromo = payload.articlePromo;
      state.articleCategory = payload.articleCategory;
      state.articlePhoto = payload.articlePhoto;
    },
    SET_PUBLISHED_ARTICLES(state, payload) {
      state.articles.push(payload);
    },
    SET_EDUCATION_ARTICLES(state, payload) {
      state.educationArticles.push(payload);
    },
    SET_UNPUBLISHED_ARTICLES(state, payload) {
      state.articles.push(payload);
      state.unpublishedArticles.push(payload);
    },

    SET_UPDATED_ARTICLES(state, payload) {
      const index = state.articles.findIndex((article) => {
        return article.userId === article.userId;
      });
      state.articles[index] = payload;
    },
    SET_SUBSCRIPTIONS(state, payload) {
      state.subscriptions.push(payload);
    },
    SET_SUBSCRIPTIONS_INVOICES(state, payload) {
      state.subscriptionInvoices.push(payload);
    },
    SET_SUBSCRIPTION(state, payload) {
      state.subscription = payload.data();
    },
    SUBSCRIPTION_LOADED(state) {
      state.subscriptionLoaded = true;
    },

    SET_SCHOOLS(state, payload) {
      state.schools.push(payload);
    },
    SET_SEARCHED_SCHOOLS(state, payload) {
      state.searchedSchools.push(payload);
    },
    SET_UPDATED_SCHOOL(state, payload) {
      const index = state.schools.findIndex((school) => {
        return school.userId === payload.userId;
      });
      state.schools[index] = payload;
    },
    SET_SEARCH_INPUT(state, payload) {
      state.searchInput = payload;
    },
    SET_COURSES(state, payload) {
      state.courses.push(payload);
    },
    SET_UPDATED_COURSES(state, payload) {
      const index = state.courses.findIndex((course) => {
        return course.userId === payload.userId;
      });
      state.courses[index] = payload;
    },
    SET_ADVERTISING_SPOTS(state, payload) {
      state.advertisingSpots.push(payload);
    },
    SET_UPDATED_ADVERTISING_SPOTS(state, payload) {
      const index = state.advertisingSpots.findIndex((course) => {
        return course.userId === payload.userId;
      });
      state.advertisingSpots[index] = payload;
    },
    PROFILE_LOADED(state) {
      state.userLoaded = true;
    },
    ACCOUNT_LOADED(state) {
      state.accountLoaded = true;
    },
    MENU_LOADED(state) {
      state.menuLoaded = true;
    },
    PHOTOS_LOADED(state) {
      state.photosLoaded = true;
    },
  },
  actions: {
    async GET_CURRENT_PROFILE({ dispatch, commit }, user) {
      const dataBase = await db
        .collection("accounts")
        .doc(fb.auth().currentUser.uid);
      const dbResults = await dataBase.get();

      const token = await user.getIdTokenResult();
      const admin = await token.claims.admin;
      if (admin) {
        commit("SET_PROFILE_ADMIN", { admin: admin, dbResults: dbResults });
      }
      commit("SET_PROFILE_INFO", dbResults);
      commit("SET_PROFILE_INITIALS");
      commit("PROFILE_LOADED");
      await dispatch("GET_SUBSCRIPTION");
      await dispatch("GET_ADMIN_LEGAL");
    },
    async GET_ADMIN_LEGAL({ commit }) {
      const dataBase = await db.collection("legal").doc("ro");
      const dbResults = await dataBase.get();
      commit("SET_ADMIN_LEGAL", dbResults);
    },

    async GET_SCHOOL_ACCOUNT({ dispatch, commit, state }, username) {
      if (
        !state.allAccountsVisited.some(
          (account) => account.username === username
        )
      ) {
        const dataBase = await db
          .collection("accounts")
          .where("accountType", "==", "School")
          .where("username", "==", username);
        const dbResults = await dataBase.get();
        if (dbResults.docs[0]) {
          const account = {
            accountType: dbResults.docs[0].data().accountType,
            username: dbResults.docs[0].data().username,
            name: dbResults.docs[0].data().name,
            userId: dbResults.docs[0].data().userId,
            profilePhoto: dbResults.docs[0].data().profilePhoto,
            acreditata: dbResults.docs[0].data().acreditata,
            address: dbResults.docs[0].data().address,
            viewSchedule: dbResults.docs[0].data().viewSchedule,
            viewMenu: dbResults.docs[0].data().viewMenu,
            viewTeachers: dbResults.docs[0].data().viewTeachers,
            viewAfterSchool: dbResults.docs[0].data().viewAfterSchool,
            viewOptionals: dbResults.docs[0].data().viewOptionals,
            viewAdmissions: dbResults.docs[0].data().viewAdmissions,
            viewCosts: dbResults.docs[0].data().viewCosts,
            mobilePhone: dbResults.docs[0].data().mobilePhone,
            email: dbResults.docs[0].data().email,
            socials: dbResults.docs[0].data().socials,
            promotionPhotos: dbResults.docs[0].data().promotionPhotos,
            description: dbResults.docs[0].data().description,
            capacity: dbResults.docs[0].data().capacity,
            groupSize: dbResults.docs[0].data().groupSize,
            year: dbResults.docs[0].data().year,
            teachers: dbResults.docs[0].data().teachers,
            descriptionExtension: dbResults.docs[0].data().descriptionExtension,
            geoPoint: dbResults.docs[0].data().geoPoint,
            status: dbResults.docs[0].data().status,
            published: dbResults.docs[0].data().published,
            director: dbResults.docs[0].data().director,
            foodMenu: [],
          };
          commit("SET_ACCOUNT", account);
          commit("ACCOUNT_LOADED");
          commit("SET_VISITED_ACCOUNT", username);
          await dispatch("GET_FOOD_MENU", username);
          commit("MENU_LOADED");
        } else {
          router.push({ name: "default" });
        }
      } else {
        commit("SET_VISITED_ACCOUNT", username);
      }
      return;
    },
    async GET_FOOD_MENU({ commit }, username) {
      const dataBase = await db
        .collection("food")
        .where("username", "==", username);
      const dbResults = await dataBase.get();
      if (dbResults.docs[0]) {
        const food = {
          menu: dbResults.docs[0].data().menu,
          username: dbResults.docs[0].data().username,
          breakfastTime: dbResults.docs[0].data().breakfastTime,
          lunchTime: dbResults.docs[0].data().lunchTime,
          snacksTime: dbResults.docs[0].data().snacksTime,
          userId: dbResults.docs[0].data().userId,
        };
        commit("SET_FOOD_MENU", { food: food, username: username });
      }
      return;
    },
    async GET_ACCOUNTS({ commit, state }) {
      const dataBase = await db.collection("accounts");
      const dbResults = await dataBase.get();
      const accounts = dbResults;
      accounts.forEach((doc) => {
        if (
          !state.accounts.some(
            (account) => account.userId === doc.data().userId
          ) &&
          doc.data().userId
        ) {
          const data = {
            userId: doc.data().userId,
            username: doc.data().username,
            profilePhoto: doc.data().profilePhoto,
            email: doc.data().email,
          };
          commit("SET_ACCOUNTS", data);
        }
      });
    },
    async GET_ALBUM_YEARS({ dispatch, commit, state }, username) {
      const dataBase = await db
        .collection("albums")
        .where("username", "==", username)
        .orderBy("year", "desc");
      const dbResults = await dataBase.get();
      const albumYears = dbResults;
      albumYears.forEach((doc) => {
        if (
          !state.albumYears.some((album) => album.year === doc.data().year) &&
          doc.data().docId
        ) {
          const data = {
            username: doc.data().username,
            docId: doc.data().docId,
            year: doc.data().year,
          };
          commit("SET_ALBUM_YEARS", data);
        }
      });
      if (state.albumYears) {
        await dispatch("GET_ACCOUNT_ALBUMS", {
          username: username,
          year: state.albumYears[0],
        });
      }
    },
    async GET_ACCOUNT_ALBUMS({ commit, state }, options) {
      state.albums = [];
      const dataBase = await db
        .collection("albums")
        .where("username", "==", options.username)
        .where("year", "==", options.year);
      const dbResults = await dataBase.get();
      const albums = dbResults;
      albums.forEach((doc) => {
        if (
          !state.albums.some((album) => album.docId === doc.data().docId) &&
          doc.data().docId
        ) {
          const data = {
            albums: doc.data().albums,
            year: doc.data().year,
            docId: doc.data().docId,
            username: doc.data().username,
          };
          commit("SET_ALBUMS", data);
        }
      });
    },

    async GET_CUSTOM_ACCOUNTS({ commit, state }, options) {
      const dataBase = await db
        .collection("accounts")
        .where("accountType", "==", options.type);
      const dbResults = await dataBase.get();
      const customAccounts = dbResults;
      customAccounts.forEach((doc) => {
        if (
          !state.customAccounts.some(
            (account) => account.userId === doc.data().userId
          ) &&
          doc.data().userId
        ) {
          const data = {
            userId: doc.data().userId,
            username: doc.data().username,
          };
          commit("SET_CUSTOM_ACCOUNTS", data);
        }
      });
    },
    async GET_CUSTOM_VFORCE_ACCOUNTS({ commit, state }) {
      const dataBase = await dbVforce
        .collection("accounts")
        .where("accountType", "==", "Streamer");
      const dbResults = await dataBase.get();
      const customAccounts = dbResults;
      customAccounts.forEach((doc) => {
        if (
          !state.customAccounts.some(
            (account) => account.userId === doc.data().userId
          ) &&
          doc.data().userId
        ) {
          const data = {
            username: doc.data().username,
            profilePhoto: doc.data().profilePhoto,
            socials: doc.data().socials,
          };
          commit("SET_CUSTOM_ACCOUNTS", data);
        }
      });
    },
    async UPLOAD_MULTIPLE_PHOTOS({ commit, state }, docId) {
      let addedPercentage = 100 / state.multiplePhotoFiles.length;
      let itemsProcessed = 0;
      await state.multiplePhotoFiles.forEach(async (file, index) => {
        setTimeout(() => {
          commit("SET_UPDATED_PROGRESS_BAR_VALUE", addedPercentage);
          itemsProcessed++;
          if (itemsProcessed === state.multiplePhotoFiles.length) {
            commit("SET_UPDATED_PROGRESS_BAR");
            commit("SET_MULTIPLE_PHOTO_FILES", []);
            db.collection("albums").doc(docId).update({ albums: state.albums });
          }
          let storageRef = fb
            .storage()
            .ref("/" + state.profileUserId + "/" + docId + "/" + file.name);
          let uploadTask = storageRef.put(file);
          uploadTask.on(
            "state_changed",
            // eslint-disable-next-line
            (snapshot) => {},
            // eslint-disable-next-line
            (error) => {},
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                let newImages = {
                  title: "",
                  url: downloadURL,
                  id: itemsProcessed,
                };

                state.albums[state.selectedAlbumIndex].photos.push(newImages);
              });
            }
          );
        }, index * 1000);
      });
    },

    async UPDATE_ALBUM_PHOTO({ state }, file) {
      let storageRef = fb
        .storage()
        .ref(
          "/" +
            state.profileUserId +
            "/" +
            state.albumId +
            "/album/" +
            file.name
        );
      let uploadTask = storageRef.put(file);
      uploadTask.on(
        "state_changed",
        // eslint-disable-next-line
        (snapshot) => {},
        // eslint-disable-next-line
        (error) => {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            state.albums[state.selectedAlbumIndex].url = downloadURL;
            db.collection("albums")
              .doc(state.albumId)
              .update({ albums: state.albums, userId: state.profileUserId });
          });
        }
      );
    },
    async UPDATE_SPECIFIC_ALBUM_PHOTO({ state }, options) {
      let storageRef = fb
        .storage()
        .ref(
          "/" +
            state.profileUserId +
            "/" +
            state.albumId +
            "/album/" +
            options.file.name
        );
      let uploadTask = storageRef.put(options.file);
      uploadTask.on(
        "state_changed",
        // eslint-disable-next-line
        (snapshot) => {},
        // eslint-disable-next-line
        (error) => {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            state.albums[state.selectedAlbumIndex].photos[options.index].url =
              downloadURL;

            db.collection("albums")
              .doc(state.albumId)
              .update({ albums: state.albums, userId: state.profileUserId });
          });
        }
      );
    },
    async UPLOAD_PROMOTED_PHOTOS({ state }, options) {
      let storageRef = fb
        .storage()
        .ref("/" + state.profileUserId + "/promoPhotos/" + options.file.name);
      let uploadTask = storageRef.put(options.file);
      uploadTask.on(
        "state_changed",
        // eslint-disable-next-line
        (snapshot) => {},
        // eslint-disable-next-line
        (error) => {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            state.promotionPhotos[options.index].url = downloadURL;
            let update = {
              promotionPhotos: state.promotionPhotos,
              userId: state.profileUserId,
            };

            db.collection("accounts").doc(state.profileUserId).update(update);
          });
        }
      );
    },
    async UPLOAD_PROFILE_PHOTO({ commit, state }, file) {
      let storageRef = fb
        .storage()
        .ref("/" + state.profileUserId + "/" + file.name);
      let uploadTask = storageRef.put(file);
      await uploadTask.on(
        "state_changed",
        // eslint-disable-next-line
        (snapshot) => {},
        // eslint-disable-next-line
        (error) => {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            commit("SET_PROFILE_PHOTO", downloadURL);
            let account = {
              profilePhoto: downloadURL,
              userId: state.profileUserId,
            };
            db.collection("accounts").doc(state.profileUserId).update(account);
          });
        }
      );
    },
    async UPDATE_ACCOUNT_LOCATION({ state }, account) {
      let address = `${account.route}  ${account.street_number},  ${account.sublocality_level_1}, ${account.locality}, ${account.country}, ${account.postal_code}`;
      let { data } = await axios.post(
        "https://us-central1-eduprivate2020.cloudfunctions.net/geocodeAddressAndSave",
        {
          address: address,
          city: account.locality,
          postal_code: account.postal_code,
          street: account.route,
          street_number: account.street_number,
          address_sector: account.sublocality_level_1,
          address_area: account.administrative_area_level_1,
          country: account.country,
          userId: state.profileUserId,
        }
      );

      if (data === "No Results") {
        alert("No results for address");
        return;
      }
    },
    async UPDATE_ACCOUNT({ dispatch }, account) {
      let updateAccount = db.collection("accounts").doc(account.userId);
      await updateAccount.update(account);
      await dispatch("GET_ACCOUNTS");
    },
    async GET_SCHOOLS({ commit, state }) {
      const dataBase = await db
        .collection("accounts")
        .where("accountType", "==", "School")
        .where("published", "==", true)
        .orderBy("acreditata", "desc");

      const dbResults = await dataBase.get();
      const schools = dbResults;
      schools.forEach((doc) => {
        if (
          !state.schools.some(
            (school) => school.userId === doc.data().userId
          ) &&
          doc.data().userId
        ) {
          const data = {
            userId: doc.data().userId,
            username: doc.data().username,
            description: doc.data().description,
            name: doc.data().name,
            profilePhoto: doc.data().profilePhoto,
            acreditata: doc.data().acreditata,
          };
          commit("SET_SCHOOLS", data);
        }
      });
    },
    async GET_SEARCHED_SCHOOLS({ commit, state }, location) {
      state.searchedSchools = [];
      const dataBase = await db
        .collection("accounts")
        .where("accountType", "==", "School")
        .where("locationFilter", "array-contains", location)
        .where("published", "==", true)
        .orderBy("acreditata", "desc");

      const dbResults = await dataBase.get();
      const searchedSchools = dbResults;
      searchedSchools.forEach((doc) => {
        if (
          !state.searchedSchools.some(
            (school) => school.userId === doc.data().userId
          ) &&
          doc.data().userId
        ) {
          const data = {
            userId: doc.data().userId,
            username: doc.data().username,
            profilePhoto: doc.data().profilePhoto,
            email: doc.data().email,
            geoPoint: doc.data().geoPoint,
            status: doc.data().status,
            favorite: doc.data().favorite,
            acreditataFilter: doc.data().acreditataFilter,
            promotionPhotos: doc.data().promotionPhotos,
            locationFilter: doc.data().locationFilter,
            filters: doc.data().filters,
            name: doc.data().name,
            address: doc.data().address,
            levels: doc.data().levels,
            afterSchool: doc.data().afterSchool,
            description: doc.data().description,
            acreditata: doc.data().acreditata,
          };
          commit("SET_SEARCHED_SCHOOLS", data);
        }
      });
    },
    async GET_PROMOTED_ACCOUNTS({ commit, state }) {
      state.searchedSchools = [];
      const dataBase = await db
        .collection("accounts")
        .where("promotion", "==", true);

      const dbResults = await dataBase.get();
      const promotedAccounts = dbResults;
      promotedAccounts.forEach((doc) => {
        if (
          !state.promotedAccounts.some(
            (account) => account.userId === doc.data().userId
          ) &&
          doc.data().userId
        ) {
          const data = {
            userId: doc.data().userId,
            username: doc.data().username,
            promotionPhotos: doc.data().promotionPhotos,
            name: doc.data().name,
            address: doc.data().address,
            city: doc.data().city,
          };
          commit("SET_PROMOTED_ACCOUNTS", data);
        }
      });
    },

    async GET_COURSES({ commit, state }) {
      const dataBase = await db
        .collection("accounts")
        .where("accountType", "==", "Course")
        .where("published", "==", true);
      const dbResults = await dataBase.get();
      const courses = dbResults;
      courses.forEach((doc) => {
        if (
          !state.courses.some(
            (course) => course.userId === doc.data().userId
          ) &&
          doc.data().userId
        ) {
          const data = {
            userId: doc.data().userId,
            username: doc.data().username,
            profilePhoto: doc.data().profilePhoto,
            email: doc.data().email,
          };
          commit("SET_COURSES", data);
        }
      });
    },
    async GET_ADVERTISING_SPOTS({ commit, state }, options) {
      const dataBase = await db
        .collection(options.advertisingType)
        .where("username", "==", options.username);
      const dbResults = await dataBase.get();
      const advertisingSpots = dbResults;
      advertisingSpots.forEach((doc) => {
        if (
          !state.advertisingSpots.some(
            (spot) => spot.docId === doc.data().docId
          ) &&
          doc.data().userId
        ) {
          const data = {
            userId: doc.data().userId,
            docId: doc.data().docId,
            title: doc.data().title,
            spotLink: doc.data().classLink,
            spotPhoto: doc.data().classPhoto,
            username: doc.data().username,
          };
          commit("SET_ADVERTISING_SPOTS", data);
        }
      });
    },
    async GET_ARTICLES({ commit, state }) {
      const dataBase = await db
        .collection("articles")
        .where("published", "==", true)
        .orderBy("date", "desc");
      const dbResults = await dataBase.get();
      const articles = dbResults;
      articles.forEach((doc) => {
        if (
          !state.articles.some(
            (article) => article.docId === doc.data().docId
          ) &&
          doc.data().docId
        ) {
          const data = {
            docId: doc.data().docId,
            articleStructure: doc.data().articleStructure,
            title: doc.data().title,
            date: moment(doc.data().date.toDate()).format("MM/DD/YYYY"),
            articlePhoto: doc.data().articlePhoto,
            category: doc.data().category,
            published: doc.data().published,
          };
          commit("SET_PUBLISHED_ARTICLES", data);
        }
      });
    },
    async GET_EDUCATION_ARTICLES({ commit, state }) {
      const dataBase = await db
        .collection("articles")
        .where("category", "==", "educatie")
        .where("published", "==", true)
        .orderBy("date", "desc")
        .limit(3);
      const dbResults = await dataBase.get();
      const educationArticles = dbResults;
      educationArticles.forEach((doc) => {
        if (
          !state.educationArticles.some(
            (article) => article.docId === doc.data().docId
          ) &&
          doc.data().docId
        ) {
          const data = {
            docId: doc.data().docId,
            articleStructure: doc.data().articleStructure,
            title: doc.data().title,
            date: moment(doc.data().date.toDate()).format("MM/DD/YYYY"),
            articlePhoto: doc.data().articlePhoto,
            category: doc.data().category,
            published: doc.data().published,
          };

          commit("SET_EDUCATION_ARTICLES", data);
        }
      });
    },
    async GET_ARTICLE({ commit }, docId) {
      const dataBase = await db.collection("articles").doc(docId);
      const dbResults = await dataBase.get();
      const article = dbResults;
      if (article) {
        commit("SET_ARTICLE", article);
      } else {
        router.push({ name: "default" });
      }
    },
    async GET_SUBSCRIPTIONS({ commit, state }) {
      const dataBase = await db
        .collection("subscriptions")
        .orderBy("metadata.id");
      const dbResults = await dataBase.get();
      const subscriptions = dbResults;
      subscriptions.forEach(async (doc) => {
        if (
          !state.subscriptions.some(
            (subscription) => subscription.name === doc.data().name
          ) &&
          doc.data().name
        ) {
          const dataBase = await db
            .collection("subscriptions")
            .doc(doc.id)
            .collection("prices");
          const dbResults = await dataBase.get();
          const prices = dbResults;
          let pricesList = [];
          prices.forEach((price) => {
            if (
              !state.subscriptionsPrices.some(
                (item) => item.product === price.data().product
              ) &&
              price.data().interval
            ) {
              pricesList.push({
                id: price.id,
                ...price.data(),
              });
            }
          });
          const data = {
            name: doc.data().name,
            metadata: doc.data().metadata,
            prices: pricesList,
          };
          commit("SET_SUBSCRIPTIONS", data);
        }
      });
    },
    async GET_SUBSCRIPTION({ commit, state }) {
      const dataBase = await db
        .collection("clients")
        .doc(state.profileUserId)
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active", "past_due", "unpaid"]);
      const dbResults = await dataBase.get();
      const subscription = dbResults.docs[0];
      if (subscription !== undefined) {
        const dataBaseInvoices = await db
          .collection("clients")
          .doc(state.profileUserId)
          .collection("subscriptions")
          .doc(subscription.id)
          .collection("invoices")
          .orderBy("created", "desc");
        const dbResultsInvoices = await dataBaseInvoices.get();
        const subscriptionInvoices = dbResultsInvoices;
        subscriptionInvoices.forEach(async (doc) => {
          if (
            !state.subscriptionInvoices.some(
              (invoice) => invoice.id === doc.data().id
            ) &&
            doc.data().id
          ) {
            let amount = doc.data().total.toString();
            amount = amount.slice(0, 3) + ",00";
            const data = {
              id: doc.data().id,
              currency: doc.data().currency,
              active: doc.data().active,
              status: doc.data().status,
              date: doc.data().created,
              amount: amount,
              type: doc.data().object,
            };
            commit("SET_SUBSCRIPTIONS_INVOICES", data);
          }
        });

        commit("SET_SUBSCRIPTION", subscription);
        commit("SUBSCRIPTION_LOADED");
      } else {
        state.subscription = "default";
        commit("SUBSCRIPTION_LOADED");
      }
    },
  },
  getters: {},
});
