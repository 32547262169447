<template>
  <v-app-bar
    app
    elevation="0"
    :class="isTransparent && route === 'homeView' ? 'transparent' : 'purple'"
  >
    <LogoNav />
    <SearchBar
      v-if="
        route !== 'homeView' &&
        route !== 'blogView' &&
        route !== 'blogEducation' &&
        route !== 'blogGaming' &&
        route !== 'articleView'
      "
    />
    <NavLinks />
    <!-- <v-toolbar-items class="hidden-xs-only mr-2">
        <v-btn text link to="/shop">
          <span class="white--text ml-2">
            <v-icon left class="white--text">mdi-store</v-icon>
          </span>
        </v-btn>
      </v-toolbar-items> -->
    <v-spacer></v-spacer>

    <ProfileNav />
  </v-app-bar>
</template>
<script>
import logo from "../../assets/owlearningWhite.png";
import SearchBar from "../../../src/components/SearchBar";
import LogoNav from "../navigation/LogoNav";
import NavLinks from "../navigation/NavLinks";
import ProfileNav from "./profileNavigation/_ProfileNav.vue";
import { mapState } from "vuex";
export default {
  name: "DefaultNav",
  components: {
    SearchBar,
    LogoNav,
    ProfileNav,
    NavLinks,
  },

  data() {
    return {
      logoIcon: logo,
      isTransparent: true,
    };
  },
  computed: {
    ...mapState(["route"]),
  },
  methods: {
    handleScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition > 0) {
        this.isTransparent = false;
      } else if (currentScrollPosition === 0) {
        this.isTransparent = true;
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped>
:deep(
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
      > .v-input__control
      > .v-input__slot
  ) {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
input#input {
  margin-left: 10px;
}
:deep(.v-text-field__details) {
  display: none;
}
:deep(.v-messages.theme--light) {
  display: none;
}
:deep(div.v-input__slot.white) {
  margin-bottom: 0px;
}
.v-btn.v-btn--flat.v-btn--text.theme--dark.v-size--default {
  padding: 0px;
}
:deep(.v-toolbar__content, .v-toolbar__extension) {
  padding: 0px !important;
}
.searchPush {
  margin-top: 4px;
}
.v-subheader.ml-1.theme--light {
  margin-top: 10px;
  height: 80%;
}
.router-link {
  text-decoration: none;
}
.router-link span {
  color: white;
}
.router-link-menu {
  text-decoration: none;
}
.router-link-menu span {
  color: black;
}
.rounded-card {
  border-radius: 14px;
}
.bgOpacity {
  background: rgba(0, 0, 0, 0.5);
  /* For IE8 and earlier */
}
</style>
